<script>
export default {
  name: 'HYRender',
  functional: true,
  props: {
    code:String,
    componentRef: String,
    control: String,
    config: Object,
    form:Object,
    on: Object
  },
  render: (h, ctx) => {
    const {code,componentRef, control, config,form, on } = ctx.props
    return h(control, { key:code, ref: componentRef,attrs: config.attrs, props: { config,form }, on })
  }
}
</script>
