export default (pageCtx, extdata) => [
  {
    riskName: "机动车损失保险",
    riskCode: "COMM1A01",
    type: "main",
    parentCode: null,
    control: "COMM0A01",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        show: true,
        config: {
          label: "实际价值",
          code: "amount",
          placeholder: "实际价值",
          disabled: false,
          val: 0,
          unit: "元",
          rules: [],
          on: {
            //事件配置
          },
        },
      },
      {
        code: "deductible",
        control: "HInput",
        show: true,
        config: {
          label: "绝对免赔额",
          code: "deductible",
          placeholder: "绝对免赔额",
          disabled: false,
          val: '0',
          unit: "元",
          rules: [],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加车轮单独损失险",
    riskCode: "COMM1A02",
    type: "extra",
    parentCode: "COMM1A01",
    control: "Form",
    seq: "1",
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        span: 24,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          unit: "元",
          options: [], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加车轮单独损失险保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加车轮单独损失险格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加车身划痕损失险",
    riskCode: "COMM1A03",
    type: "extra",
    parentCode: "COMM1A01",
    control: "Select",
    seq: "1",
    formItems: [
      {
        code: "amount",
        control: "HSelect",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          options: [
            {
              value: 2000,
              label: "2000",
            },
            {
              value: 5000,
              label: "5000",
            },
            {
              value: 10000,
              label: "1万",
            },
            {
              value: 20000,
              label: "2万",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加车身划痕损失险保额不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
//   {
//     riskName: "附加发动机损坏除外特约条款",
//     riskCode: "COMM1A04",
//     type: "extra",
//     parentCode: "COMM1A01",
//     control: "Empty",
//     seq: "1",
//   },
  {
    riskName: "附加绝对免赔率特约险(机动车损失保险)",
    riskCode: "COMM1A05",
    type: "extra",
    parentCode: "COMM1A01",
    control: "Select",
    seq: "1",
    formItems: [
      {
        code: "deductibleRate",
        control: "HSelect",
        seq: "1",
        show: true,
        config: {
          label: "绝对免赔率",
          code: "deductibleRate",
          placeholder: "绝对免赔率",
          disabled: false,
          val: null,
          options: [
            {
              value: 0.0,
              label: "0%",
            },
            {
              value: 0.05,
              label: "5%",
            },
            {
              value: 0.1,
              label: "10%",
            },
            {
              value: 0.15,
              label: "15%",
            },
            {
              value: 0.2,
              label: "20%",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return "" + value;
              },
              message: "附加绝对免赔率特约险(机动车损失保险)绝对免赔率不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "机动车第三者责任保险",
    riskCode: "COMM1B01",
    type: "main",
    parentCode: null,
    control: "Select",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HSelect",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          options: [
            {
              value: 100000,
              label: "10万",
            },
            {
              value: 150000,
              label: "15万",
            },
            {
              value: 200000,
              label: "20万",
            },
            {
              value: 300000,
              label: "30万",
            },
            {
              value: 500000,
              label: "50万",
            },
            {
              value: 1000000,
              label: "100万",
            },
            {
              value: 1500000,
              label: "150万",
            },
            {
              value: 2000000,
              label: "200万",
            },
            {
              value: 3000000,
              label: "300万",
            },
            {
              value: 4000000,
              label: "400万",
            },
            {
              value: 5000000,
              label: "500万",
            },
            {
              value: 6000000,
              label: "600万",
            },
            {
              value: 7000000,
              label: "700万",
            },
            {
              value: 8000000,
              label: "800万",
            },
            {
              value: 9000000,
              label: "900万",
            },
            {
              value: 10000000,
              label: "1000万",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "机动车第三者责任保险不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加精神损害抚慰金责任险(三者)",
    riskCode: "COMM1B02",
    type: "extra",
    parentCode: "COMM1B01",
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加精神损害抚慰金责任险(三者)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加精神损害抚慰金责任险(三者)格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加医保外用药责任险(三者)",
    riskCode: "COMM1B03",
    type: "extra",
    parentCode: "COMM1B01",
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加医保外用药责任险(三者)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加医保外用药责任险(三者)格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加绝对免赔率特约险(三者)",
    riskCode: "COMM1B04",
    type: "extra",
    parentCode: "COMM1B01",
    control: "Select",
    seq: 1,
    formItems: [
      {
        code: "deductibleRate",
        control: "HSelect",
        seq: "1",
        show: true,
        config: {
          label: "绝对免赔率",
          code: "deductibleRate",
          placeholder: "绝对免赔率",
          disabled: false,
          val: null,
          options: [
            {
              value: 0.0,
              label: "0%",
            },
            {
              value: 0.05,
              label: "5%",
            },
            {
              value: 0.1,
              label: "10%",
            },
            {
              value: 0.15,
              label: "15%",
            },
            {
              value: 0.2,
              label: "20%",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return "" + value;
              },
              message: "附加绝对免赔率特约险(三者)绝对免赔率不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加法定节假日限额翻倍险",
    riskCode: "COMM1B05",
    type: "extra",
    parentCode: "COMM1B01",
    control: "Empty",
    seq: "1",
  },
  {
    riskName: "车上人员责任险司机",
    riskCode: "COMM1C01",
    type: "main",
    parentCode: null,
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        span: 24,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          min: 10000,
          max: 5000000,
          disabled: false,
          val: 10000,
          unit: "元",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "车上人员责任险(司机)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "车上人员责任险(司机)格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加精神损害抚慰金责任险(司机)",
    riskCode: "COMM1C02",
    type: "extra",
    parentCode: "COMM1C01",
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加精神损害抚慰金责任险(司机)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加精神损害抚慰金责任险(司机)格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加医保外用药责任险(司机)",
    riskCode: "COMM1C03",
    type: "extra",
    parentCode: "COMM1C01",
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加医保外用药责任险(司机)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加医保外用药责任险(司机)格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加绝对免赔率特约险(司机)",
    riskCode: "COMM1C04",
    type: "extra",
    parentCode: "COMM1C01",
    control: "Select",
    seq: 1,
    formItems: [
      {
        code: "deductibleRate",
        control: "HSelect",
        seq: "1",
        show: true,
        config: {
          label: "绝对免赔率",
          code: "deductibleRate",
          placeholder: "绝对免赔率",
          disabled: false,
          val: null,
          options: [
            {
              value: 0.0,
              label: "0%",
            },
            {
              value: 0.05,
              label: "5%",
            },
            {
              value: 0.1,
              label: "10%",
            },
            {
              value: 0.15,
              label: "15%",
            },
            {
              value: 0.2,
              label: "20%",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return "" + value;
              },
              message: "附加绝对免赔率特约险(司机)绝对免赔率不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "车上人员责任险（乘客）",
    riskCode: "COMM1D01",
    type: "main",
    parentCode: null,
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HText",
        seq: "1",
        show: false,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          rules: [],
          //事件配置
          inputEvent: (val) => {
            pageCtx.pageRiskForm['COMM1D01'].amount = +pageCtx.pageRiskForm['COMM1D01'].unitAmount * pageCtx.pageRiskForm['COMM1D01'].quantity            
          }
        },
      },
      {
        code: "unitAmount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "unitAmount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          unit: "元",
          append: "X",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "车上人员责任险（乘客）保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "车上人员责任险(乘客)格式有误,请输入数字",
            },
          ],
          //事件配置
          inputEvent: (val) => {
            pageCtx.pageRiskForm['COMM1D01'].amount = +pageCtx.pageRiskForm['COMM1D01'].unitAmount * pageCtx.pageRiskForm['COMM1D01'].quantity            
          }
        },
      },
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "座位",
          code: "quantity",
          placeholder: "座位数",
          disabled: true,
          val: null,
          unit: "座",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "车上人员责任险（乘客）座位不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加精神损害抚慰金责任险(乘客)",
    riskCode: "COMM1D02",
    type: "extra",
    parentCode: "COMM1D01",
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加精神损害抚慰金责任险(乘客)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加精神损害抚慰金责任险(乘客)格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加医保外用药责任险(乘客)",
    riskCode: "COMM1D03",
    type: "extra",
    parentCode: "COMM1D01",
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "unitAmount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "unitAmount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          unit: "元",
          append: "X",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加医保外用药责任险(乘客)保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加医保外用药责任险(乘客)格式有误,请输入数字",
            },
          ],
          //事件配置
          inputEvent: (val) => {
            pageCtx.pageRiskForm['COMM1D03'].amount = +pageCtx.pageRiskForm['COMM1D03'].unitAmount * pageCtx.pageRiskForm['COMM1D03'].quantity            
          }
        },
      },
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "座位",
          code: "quantity",
          placeholder: "座位",
          disabled: true,
          val: null,
          unit: "座",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "车上人员责任险（乘客）座位不能为空",
            },
          ],
          //事件配置
          inputEvent: (val) => {
            pageCtx.pageRiskForm['COMM1D03'].amount = +pageCtx.pageRiskForm['COMM1D03'].unitAmount * pageCtx.pageRiskForm['COMM1D03'].quantity            
          }
        },
      },
    ],
  },
  {
    riskName: "附加绝对免赔率特约险(乘客)",
    riskCode: "COMM1D04",
    type: "extra",
    parentCode: "COMM1D01",
    control: "Select",
    seq: 1,
    formItems: [
      {
        code: "deductibleRate",
        control: "HSelect",
        seq: "1",
        show: true,
        config: {
          label: "绝对免赔率",
          code: "deductibleRate",
          placeholder: "绝对免赔率",
          disabled: false,
          val: null,
          options: [
            {
              value: 0.0,
              label: "0%",
            },
            {
              value: 0.05,
              label: "5%",
            },
            {
              value: 0.1,
              label: "10%",
            },
            {
              value: 0.15,
              label: "15%",
            },
            {
              value: 0.2,
              label: "20%",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return "" + value;
              },
              message: "附加绝对免赔率特约险(乘客)绝对免赔率不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加外部电网故障损失险",
    riskCode: "COMM1M01",
    type: "extra",
    parentCode: null,
    control: "Form",
    seq: "1",
    formItems: [
      {
        code: "amount",
        control: "HInput",
        seq: "1",
        show: true,
        span: 24,
        config: {
          label: "保额",
          code: "amount",
          placeholder: "请输入保额",
          disabled: true,
          val: null,
          unit: "元",
          options: [], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加外部电网故障损失险保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加外部电网故障损失险格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加个人充电桩损失保险",
    riskCode: "COMM1M02",
    type: "extra",
    parentCode: "COMM1A01",
    control: "ChargePile",
    seq: 1,
    formItems: [
      {
        code: "unitAmount",
        control: "HSelectDown",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "unitAmount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          append:"元",
          options: [
            {
              value: 2000,
              label: "2000",
            },
            {
              value: 5000,
              label: "5000",
            },
            {
              value: 10000,
              label: "10000",
            },
            {
              value: 20000,
              label: "20000",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加个人充电桩损失保险保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "附加个人充电桩损失保险格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
    tableItems:[
      {
        prop: "kind",
        control: "HSelect",
        show: true,
        view: true,
        filters: "optionFormat({'1':'地面充电桩','2':'壁挂式充电桩','9':'其他类充电桩'})",
        config: {
          label: "充电桩种类",
          placeholder: "请选择",
          val: "1",
          default: "1",
          options: [
            {
              value: "1",
              label: "地面充电桩"
            },
            {
              value: "2",
              label: "壁挂式充电桩"
            },
            {
              value: "9",
              label: "其他类充电桩"
            }
          ], //下拉选项数组
          rules: [
            {
              required: true,
              message: "请选择充电桩种类",
              trigger: "onBlur"
            },
          ]
        },
      },
      {
        prop: "model",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "充电桩型号",
          val:null,
          rules: [
            {
              required: true,
              message: "请输入充电桩型号",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "code",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "充电桩编号",
          val:null,
          rules: [
            {
              required: true,
              message: "请输入充电桩编号",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "serviceLift",
        control: "HSelect",
        show: true,
        view: true,
        filters: "dictFormat('CHARGEPILE_SERVICE_LIFT')",
        config: {
          label: "使用年限",
          placeholder: "请选择",
          val: null,
          dictCode: 'CHARGEPILE_SERVICE_LIFT',
          rules: [
            {
              required: true,
              message: "请选择使用年限",
              trigger: "onBlur"
            },
          ]
        },
      },
      {
        prop: "installDate",
        control: "HDatePicker",
        show: true,
        view: true,
        filters: "dateFormat()",
        config:{
          label: "安装日期",
          val:null,
          maxDate:new Date(),
          rules: [
            {
              required: true,
              message: "请选择安装日期",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "addressType",
        control: "HSelect",
        show: true,
        view: true,
        filters: "optionFormat({'1':'地面停车场','2':'地面停车楼/地下停车场'})",
        config:{
          label: "安装地点类型",
          val:"1",
          default: "1",
          options:[
            {
              value: "1",
              label: "地面停车场"
            },
            {
              value: "2",
              label: "地面停车楼/地下停车场"
            }
          ],
          rules: [
            {
              required: true,
              message: "请选择安装地点类型",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "areaCodes",
        control: "HCascaderArea",
        show: true,
        view: true,
        filters: "areaFormat()",
        config: {
          label: "安装地点",
          placeholder: "请选择安装地点",
          disabled: false,
          val: null,
          default:[],
          rules: [
            {
              type: "array",
              required: true,
              disabled: false,
              message: "安装地点必填",
              trigger: "onChange",
            },
          ],
        },
      },
      {
        prop: "address",
        control: "HInput",
        show: true,
        view: true,
        config: {
          label: "安装详细地址",
          placeholder: "请输入安装详细地址",
          type:"textarea",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              disabled: false,
              message: "安装详细地址必填",
              trigger: "onBlur",
            },
          ],
        },
      }
    ]
  },
  {
    riskName: "附加自用充电桩责任保险",
    riskCode: "COMM1M03",
    type: "extra",
    parentCode: "COMM1B01",
    control: "ChargePile",
    seq: 1,
    formItems: [
      {
        code: "unitAmount",
        control: "HSelectDown",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "unitAmount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          append:"元",
          options: [
            {
              value: 10000,
              label: "1万",
            },
            {
              value: 20000,
              label: "2万",
            },
            {
              value: 30000,
              label: "3万",
            },
            {
              value: 50000,
              label: "5万",
            },
            {
              value: 100000,
              label: "10万",
            },
            {
              value: 200000,
              label: "20万",
            },
            {
              value: 500000,
              label: "50万",
            },
          ], //下拉选项数组
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "附加自用充电桩责任保险保额不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
    tableItems:[
      {
        prop: "kind",
        control: "HSelect",
        show: true,
        view: true,
        filters: "optionFormat({'1':'地面充电桩','2':'壁挂式充电桩','9':'其他类充电桩'})",
        config: {
          label: "充电桩种类",
          placeholder: "请选择",
          val: "1",
          default: "1",
          options: [
            {
              value: "1",
              label: "地面充电桩"
            },
            {
              value: "2",
              label: "壁挂式充电桩"
            },
            {
              value: "9",
              label: "其他类充电桩"
            }
          ], //下拉选项数组
          rules: [
            {
              required: true,
              message: "请选择充电桩种类",
              trigger: "onBlur"
            },
          ]
        },
      },
      {
        prop: "model",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "充电桩型号",
          val:null,
          rules: [
            {
              required: true,
              message: "请输入充电桩型号",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "code",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "充电桩编号",
          val:null,
          rules: [
            {
              required: true,
              message: "请输入充电桩编号",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "serviceLift",
        control: "HSelect",
        show: true,
        view: true,
        filters: "dictFormat('CHARGEPILE_SERVICE_LIFT')",
        config: {
          label: "使用年限",
          placeholder: "请选择",
          val: null,
          dictCode: 'CHARGEPILE_SERVICE_LIFT',
          rules: [
            {
              required: true,
              message: "请选择使用年限",
              trigger: "onBlur"
            },
          ]
        },
      },
      {
        prop: "installDate",
        control: "HDatePicker",
        show: true,
        view: true,
        filters: "dateFormat()",
        config:{
          label: "安装日期",
          val:null,
          maxDate:new Date(),
          rules: [
            {
              required: true,
              message: "请选择安装日期",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "addressType",
        control: "HSelect",
        show: true,
        view: true,
        filters: "optionFormat({'1':'地面停车场','2':'地面停车楼/地下停车场'})",
        config:{
          label: "安装地点类型",
          val:"1",
          default: "1",
          options:[
            {
              value: "1",
              label: "地面停车场"
            },
            {
              value: "2",
              label: "地面停车楼/地下停车场"
            }
          ],
          rules: [
            {
              required: true,
              message: "请选择安装地点类型",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "areaCodes",
        control: "HCascaderArea",
        show: true,
        view: true,
        filters: "areaFormat()",
        config: {
          label: "安装地点",
          placeholder: "请选择安装地点",
          disabled: false,
          val: null,
          default:[],
          rules: [
            {
              type: "array",
              required: true,
              disabled: false,
              message: "安装地点必填",
              trigger: "onChange",
            },
          ],
        },
      },
      {
        prop: "address",
        control: "HInput",
        show: true,
        view: true,
        config: {
          label: "安装详细地址",
          placeholder: "请输入安装详细地址",
          type:"textarea",
          disabled: false,
          val: null,
          rules: [
            {
              required: true,
              disabled: false,
              message: "安装详细地址必填",
              trigger: "onBlur",
            },
          ],
        },
      }
    ]
  },
//   {
//     riskName: "附加自动驾驶软件（OTA）损失补偿险",
//     riskCode: "COMM1M04",
//     type: "extra",
//     parentCode: null,
//     control: "Form",
//     seq: "1",
//     formItems: [
//       {
//         code: "amount",
//         control: "HInput",
//         seq: "1",
//         show: true,
//         span: 24,
//         config: {
//           label: "保额",
//           code: "amount",
//           placeholder: "请输入保额",
//           disabled: false,
//           val: null,
//           unit: "元",
//           options: [], //下拉选项数组
//           rules: [
//             {
//               required: true,
//               transform: (value) => {
//                 return value && "" + value;
//               },
//               message: "附加自动驾驶软件（OTA）损失补偿险保额不能为空",
//             },
//             {
//               pattern: /^\d*.?\d+$/,
//               message: "附加自动驾驶软件（OTA）损失补偿险格式有误,请输入数字",
//             },
//           ],
//           on: {
//             //事件配置
//           },
//         },
//       },
//     ],
//   },
//   {
//     riskName: "附加火灾事故限额翻倍险",
//     riskCode: "COMM1M05",
//     type: "extra",
//     parentCode: null,
//     control: "Select",
//     seq: 1,
//     formItems: [
//       {
//         code: "quantity",
//         control: "HSelect",
//         seq: "1",
//         show: true,
//         config: {
//           label: "倍数",
//           code: "quantity",
//           placeholder: "倍数",
//           disabled: false,
//           val: null,
//           options: [
//             {
//               value: 2,
//               label: "2倍",
//             },
//             {
//               value: 3,
//               label: "3倍",
//             },
//             {
//               value: 4,
//               label: "4万",
//             },
//           ], //下拉选项数组
//           rules: [
//             {
//               required: true,
//               transform: (value) => {
//                 return value && "" + value;
//               },
//               message: "附加火灾事故限额翻倍险倍数不能为空",
//             },
//           ],
//           on: {
//             //事件配置
//           },
//         },
//       },
//     ],
//   },
  {
    riskName: "附加修理期间费用补偿险",
    riskCode: "COMM1E01",
    type: "extra",
    parentCode: null,
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "unitAmount",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "保额",
          code: "unitAmount",
          placeholder: "请输入保额",
          disabled: false,
          val: null,
          unit: "元",
          append: "X",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "修理期间费用补偿险保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "修理期间费用补偿险保额格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "天数",
          code: "quantity",
          placeholder: "天数",
          val: null,
          unit: "天",
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "修理期间费用补偿险天数不能为空",
            },
            {
              pattern: /^[1-9]\d*$/,
              message: "修理期间费用补偿险天数格式有误,请输入正整数",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "附加新增加设备损失险",
    riskCode: "COMM1K01", 
    type: "extra",
    parentCode: null,
    control: "Device",
    seq: 1,
    formItems: [
      {
        prop: "toolName",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "设备名称",
          maxlength: 50,
          val:null,
          rules: [
            {
              required: true,
              message: "请输入设备名称",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "quantity",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "数量",
          val:null,
          type:"digit",
          rules: [
            {
              required: true,
              message: "请输入数量",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "productArea",
        control: "HSelect",
        show: true,
        view: true,
        filters: "optionFormat({'0':'国产','1':'进口'})",
        config:{
          label: "产地",
          val:null,
          options:[
            {
              value: "0",
              label: "国产"
            },
            {
              value: "1",
              label: "进口"
            }
          ],
          rules: [
            {
              required: true,
              message: "请选择产地",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "brand",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "品牌",
          val:null,
          rules: [
            {
              required: true,
              message: "请输入品牌",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "actualValue",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "实际价值",
          val:null,
          append:"元",
          type:"number",
          rules: [
            {
              required: true,
              message: "请输入实际价值",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "purchasingPrice",
        control: "HInput",
        show: true,
        view: true,
        config:{
          label: "购买金额",
          val:null,
          append:"元",
          type:"number",
          rules: [
            {
              required: true,
              message: "请输入购买金额",
              trigger: "onBlur"
            }
          ]
        }
      },
      {
        prop: "purchaseDate",
        control: "HDatePicker",
        show: true,
        view: true,
        filters: "dateFormat()",
        config:{
          label: "购买时间",
          maxDate:new Date(),
          val:null,
          rules: [
            {
              required: true,
              message: "请选择购买时间",
              trigger: "onBlur"
            }
          ]
        }
      }
    ],
  },
  {
    riskName: "道路救援服务特约条款",
    riskCode: "COMM1F01",
    type: "service",
    parentCode: null,
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "服务次数",
          code: "quantity",
          placeholder: "服务次数",
          disabled: false,
          val: 12,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "道路救援服务次数不能为空",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "车辆安全检测特约条款",
    riskCode: "COMM1G01",
    type: "service",
    parentCode: null,
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "服务次数",
          code: "quantity",
          placeholder: "服务次数",
          disabled: false,
          val: 1,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "车辆安全检测服务次数保额不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "车辆安全检测服务次数格式有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
    serviceItems:[
      {
        code:"01",
        label: "（一）发动机检测（机油、空滤、燃油、冷却等）",
        default:1
      },
      {
        code:"02",
        label: "（二）变速器检测",
        default:1
      },
      {
        code:"03",
        label: "（三）转向系统检测（含车轮定位测试、轮胎动平衡测试）",
      },
      {
        code:"04",
        label: "（四）底盘检测",
        default:1
      },
      {
        code:"05",
        label: "（五）轮胎检测",
        default:1
      },
      {
        code:"06",
        label: "（六）汽车玻璃检测",
        default:1
      },
      {
        code:"07",
        label: "（七）汽车电子系统检测（全车电控电器系统检测）",
        default:1
      },
      {
        code:"08",
        label: "（八）车内环境检测",
      },
      {
        code:"09",
        label: "（九）蓄电池检",
        default:1
      },
      {
        code:"10",
        label: "（十）车辆综合安全检测",
        default:1
      }
    ]
  },
  {
    riskName: "代为驾驶服务特约条款",
    riskCode: "COMM1H01",
    type: "service",
    parentCode: null,
    control: "Form",
    seq: 1,
    formItems: [
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "服务次数",
          code: "quantity",
          placeholder: "服务次数",
          disabled: false,
          val: 1,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "代为驾驶服务次数不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "代为驾驶服务次数有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
  {
    riskName: "代为送检服务特约条款",
    riskCode: "COMM1J01",
    type: "service",
    parentCode: null,
    control: "Form",
    col: 2,
    seq: 1,
    formItems: [
      {
        code: "quantity",
        control: "HInput",
        seq: "1",
        show: true,
        config: {
          label: "服务次数",
          code: "quantity",
          placeholder: "服务次数",
          disabled: false,
          val: 1,
          rules: [
            {
              required: true,
              transform: (value) => {
                return value && "" + value;
              },
              message: "代为驾驶服务次数不能为空",
            },
            {
              pattern: /^\d*.?\d+$/,
              message: "代为驾驶服务次数有误,请输入数字",
            },
          ],
          on: {
            //事件配置
          },
        },
      },
    ],
  },
];
