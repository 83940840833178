<template>
  <van-field
    v-model="val"
    v-bind="{ ...$attrs, ...elProps }"
    class="text-con">
    <template #right-icon>
        <span v-if="config.append">{{ config.append }}</span><slot name="right-icon"></slot>
    </template>
  </van-field>
</template>
<script>
export default {
  name: 'HTextAccount',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    }
  },
  data () {
    return {
      val: null
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        text: null,
        label:null,
        readonly: true,
        disabled: false,
        show: true
      }, this.config)
    },
    // currentText:{
    //   get(){
    //     return this.elProps.text||this.val
    //   },
    //   set(){
    //   }
    // }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
    }
  }
}
</script>
<style lang="scss" scoped>
.text-con::before {
  display: none;
}
.text-con ::v-deep {
  padding-top: 0px;
  .van-cell__value {
      padding: 10px;
      background: #F2F2F6;
  }
}
</style>
