<template>
    <van-field
      :label="elProps.label"
      :readonly="elProps.readonly"
      :disabled="elProps.disabled"
      :required="required"
      :labelWidth="elProps.labelWidth"
      :rules="rules">
      <template #input>
        <van-radio-group v-model="val" :icon-size="elProps.iconSize" :direction="elProps.direction" :disabled="elProps.disabled" :checked-color="elProps.checkedColor" @change="handleEvent('change')">
          <van-radio v-for="(item,index) in options" :name="item[elProps.valueKey]" :shape="elProps.shape" :key="index">{{item[elProps.labelKey]}}</van-radio>
        </van-radio-group>
      </template>
    </van-field>
</template>
<script>
import { request } from '@/api/service'
import mixins from './mixins'
export default {
  name: 'HRadio',
  mixins:[mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    },
    'config.options': {
      handler (newVal, oldVal) {
        this.options = newVal
      }
    }
  },
  data () {
    return {
      val: null,
      options: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        disabled: false,
        readonly:true,
        options: [],
        valueKey:'value',
        labelKey:'label',
        placeholder: '请选择',
        iconSize:'20px',
        direction:'horizontal',
        checkedColor:'#1989fa',
        shape:'square'
      }, this.config)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
      if (this.config.dictCode) {
        this.$set(this, 'options', this.pageContext.dictMap[this.config.dictCode])
      } else if (this.config.apiCode) {
        request({ url: this.config.apiCode, method: this.config.apiMethod || 'get' })
          .then(res => {
            this.$set(this, 'options', res || [])
          })
      }
    },
    handleEvent (eventType) {
      this.$emit('input', this.val)
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    }
  }
}
</script>
