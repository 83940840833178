<template>
  <div class="card-ocr">
    <van-action-sheet v-model="cardShow" title="身份证识别">
      <van-row class="card-side">
        <van-col span="12">
          <van-uploader :after-read="ocrCardFront" style="text-align: center;">
            <template>
              <img
                v-if="cardImg[type].front"
                width="70%"
                :src="cardImg[type].front"
              />
              <img width="70%" src="./image/card_front.png" v-else />
            </template>
          </van-uploader>
        </van-col>
        <van-col span="12">
          <van-uploader :after-read="ocrCardBack" style="text-align: center;">
            <template>
              <img
                v-if="cardImg[type].back"
                width="70%"
                :src="cardImg[type].back"
              />
              <img width="70%" src="./image/card_back.png" v-else />
            </template>
          </van-uploader>
        </van-col>
      </van-row>
    </van-action-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Notify, Toast } from "vant";
import { request } from "@/api/service";
import store from "@/store";
import ImagesQuicklyCompress from "images-quickly-compress";
export default {
  name: "CardOcr",
  mixins: [],
  props: {
    type: {
      default: '',
      type: String
    }
  },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {},
        };
      },
    },
  },
  data() {
    return {
      cardShow: false,
    };
  },
  watch: {},
  computed: {
    ...mapState("car", ["quote","quoteRes","cardImg"]),
  },
  async created() {
  },
  mounted() {},
  methods: {
    ocrCardFront(fileObj) {
      this.handleFileOcr(fileObj, {
        type: "idcard",
        code: this.type,
        side: "front",
      });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly(this.type, fileObj, "front")
      return false;
    },
    ocrCardBack(fileObj) {
      this.handleFileOcr(fileObj, {
        type: "idcard",
        code: this.type,
        side: "back",
      });
      if (!this.quote.ocr) {
        this.quote.ocr = {};
      }
      this.imagesQuickly(this.type, fileObj, "back")
      return false;
    },
    async handleFileOcr(upLoadObj, reqParam) {
      let { file } = upLoadObj;
      let isLt5M = file.size / 1024 / 1024 < 5;
      const suffixMap = {
        "image/jpeg": ".jpeg",
        "image/jpg": ".jpg",
        "image/png": ".png",
      };
      if (!isLt5M) {
        if (
          !(
            suffixMap[file.type] &&
            [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])
          )
        ) {
          Toast("只能上传5M以下的图片");
          return;
        }
        if (
          suffixMap[file.type] &&
          [".jpg", ".png", ".jpeg"].includes(suffixMap[file.type])
        ) {
          // 超过5m的图片文件, 启用压缩
          const compressFiles = await new ImagesQuicklyCompress({
            mode: "pixel", // 根据像素总大小压缩
            num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
            size: "500kb", // 图片大小超过500kb执行压缩
            imageType: file.type, // jpeg压缩效果十分理想
            quality: 0.8,
            orientation: false,
          }).compressor([file]);
          isLt5M = compressFiles[0].size / 1024 / 1024 < 5;
          file = new window.File([compressFiles[0]], file.name, {
            type: compressFiles[0].type,
          });
        }
        if (!isLt5M) {
          Toast("请上传5M以下的图片");
          return;
        }
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", reqParam.type);
      if (reqParam.side) {
        formData.append("side", reqParam.side);
      }
      // 发起请求
      request({
        url: "/afis-engine/ocr/recognition",
        method: "post",
        data: formData,
        headers: {
          "Access-Token": store.getters.info && store.getters.info.token,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.setOcrIdCard(res, reqParam.code, reqParam.side);
          this.cardShow = false;
        })
        .catch((err) => {
          Toast(err.message || "上传失败");
        });
    },
    setOcrIdCard(res, code, side) {
      const form = this.pageContext.pageForm[code];
      const { name, idNumber, address, birthDate, sex, nation, issueDate, expireDate, issueOrg } = res;

      if (side === 'front') {
        form.name = name;
        form.certificateNo = idNumber;
        form.homeAddress = address;
        form.gender = sex === "男" ? "1" : "2";
        form.birthday = Date.parse(birthDate);
        if (nation) {
            form.ethnicGroup = nation;
        }
      } else {
        form.certBeginDate = new Date(issueDate).getTime()
        form.certEndDate = new Date(expireDate).getTime()
        form.issuer = issueOrg
      }
      this.$store.dispatch("car/setQuote", this.pageContext.pageForm);
    },
    async imagesQuickly (key, fileObj, side) {
      // 超过5m的图片文件, 启用压缩
      let { file } = fileObj;
      const compressFiles = await new ImagesQuicklyCompress({
        mode: "pixel", // 根据像素总大小压缩
        num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
        size: "500kb", // 图片大小超过500kb执行压缩
        imageType: file.type, // jpeg压缩效果十分理想
        quality: 0.8,
        orientation: false,
      }).compressor([file]);
      file = new window.File([compressFiles[0]], file.name, {
        type: compressFiles[0].type,
      });
      var reader = new FileReader();
      reader.readAsDataURL(compressFiles[0]);
      reader.onload = function(){
        let cardImg = store.getters.cardImg
        cardImg[key][side] = reader.result
        store.dispatch("car/setCardImg", cardImg)
        console.log(store.getters.cardImg)
      };
    }
  },
};
</script>
<style lang="scss" scoped>
.card-ocr ::v-deep {
  .card-side {
    margin-top: 20px;
    margin-bottom: 16px;
    .type-des {
      text-align: center;
      font-size: 12px;
      color: red;
      margin-top: 10px;
      margin-bottom: 6px;
      margin-left: -20px;
    }
  }
  .van-action-sheet__header {
    border-bottom: 1px solid #ccc;
    font-size: 15px;
  }
}
</style>