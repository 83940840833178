<template>
  <div>
    <van-field
      v-model="currentText"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :readonly="elProps.readonly"
      :disabled="elProps.disabled"
      :required="required"
      :rules="rules"
      is-link
      @click="openPopup">
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-cascader
          ref="cascader"
          :v-model="val"
          :title="elProps.label"
          :options="options"
          :field-names="{
            text: elProps.labelKey,
            value: elProps.valueKey,
            children: elProps.childrenKey
          }"
          active-color="#1989fa"
          @finish="handleFinish"
          @close="handleClose"
          @change="handleChange"
        >
        </van-cascader>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { request } from '@/api/service'
import mixins from './mixins'
export default {
  name: 'HCascader',
  mixins:[mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    },
    'config.options': {
      handler (newVal, oldVal) {
        this.options = newVal
      }
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      val: [],
      currentData:null,
      options: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        show: true,
        readonly: true,
        disabled: false,
        options: [],
        valueKey:'value',
        labelKey:'label',
        childrenKey:'children',
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请选择'
      }, this.config)
    },
    currentTexts:{
      get(){
        if(!(this.val&&this.options&&this.options.length>0)){
          return null
        }
        let initOptions=[]
        this.iterationText(initOptions,this.options)
        return initOptions
      },
      set(){

      }
    },
    currentText:{
      get(){
        if(!(this.val&&this.options&&this.options.length>0)){
          return null
        }
        let initOptions=[]
        this.iterationText(initOptions,this.options)
        if(initOptions.length>0){
          return initOptions.join('/')
        }
        return null
      },
      set(){

      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
      if (this.config.dictCode) {
        this.$set(this, 'options', this.pageContext.dictMap[this.config.dictCode])
      } else if (this.config.apiCode) {
        request({ url: this.config.apiCode, method: this.config.apiMethod || 'get' })
          .then(res => {
            this.$set(this, 'options', res || [])
          })
      } else{
        this.$set(this, 'options', this.config.options)
      }
    },
    iterationText(initOptions,options){
      this.val&&this.val.forEach((itemVal)=>{
        const itemOption=options.filter(item=>{return item[this.elProps.valueKey]===itemVal})
        if(itemOption.length>0){
          const nextItem= itemOption[0]
          initOptions.push(nextItem[this.elProps.labelKey])
          nextItem[this.elProps.childrenKey]&&this.iterationText(initOptions,nextItem[this.elProps.childrenKey])
        }
      })
    },
    handleEvent (eventType) {
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    },
    openPopup(){
      if(this.elProps.disabled){
        return false
      }
      this.pageData.show=true
    },
    handleFinish({selectedOptions}){
      this.pageData.show=false
      this.val=selectedOptions.map((option) => option[this.elProps.valueKey])
      // this.currentText=selectedOptions.map((option) => option[this.elProps.labelKey]).join('/')
      this.config.val = this.val
      this.$emit('input', this.val)
      this.$emit('selected', selectedOptions)
      this.handleEvent('finish')
    },
    handleClose(){
      this.pageData.show=false
    },
    handleChange ({selectedOptions}) {
      this.val=selectedOptions.map((option) => option[this.elProps.valueKey])
      this.config.val = this.val
      this.$emit('input', this.val)
      this.handleEvent('change')
    }
  }
}
</script>
