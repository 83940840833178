<template>
  <div>
    <van-field
      v-model="currentText"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :readonly="elProps.readonly"
      :rules="elProps.rules"
      is-link
      @click="openPopup">
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" closeable position="right" :style="{ height: '100%', width:'100%'}">
        <div style="">
          <van-index-bar :index-list="indexList">
            <van-index-anchor index="A" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />

            <van-index-anchor index="B" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-index-anchor index="C" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
            <van-cell title="文本" />
          </van-index-bar>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { request } from '@/api/service'
import { findIndex } from 'lodash'
export default {
  name: 'HIndexBar',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    },
    'config.options': {
      handler (newVal, oldVal) {
        this.options = newVal
      }
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      val: null,
      currentText:null,
      indexList:['A','B','C','D','E','F'],
      options: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        show: true,
        readonly: true,
        disabled: false,
        options: [],
        valueKey:'value',
        labelKey:'label',
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请选择'
      }, this.config)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
      if (this.config.dictCode) {
        this.$set(this, 'options', this.pageContext.dictMap[this.config.dictCode])
      } else if (this.config.apiCode) {
        request({ url: this.config.apiCode, method: this.config.apiMethod || 'get' })
          .then(res => {
            this.$set(this, 'options', res || [])
          })
      }
    },
    handleEvent (eventType) {
      if (this.config[eventType + 'Event']) {
        // eslint-disable-next-line no-new-func
        new Function(`return function(ctx) { ${this.config[eventType + 'Event']} }`)()(this)
      }
    },
    openPopup(){
      this.pageData.show=true
    },
    handleConfirm(data){
      this.pageData.show=false
      this.val=data[this.elProps.valueKey]
      this.currentText=data[this.elProps.labelKey]
      this.config.val = this.val
      this.$emit('input', this.val)
    },
    handleCancel(){
      this.pageData.show=false
    }
  }
}
</script>
