<template>
    <div :style="elProps.style">
      {{currentText}}
    </div>
</template>
<script>
import mixins from './mixins'
export default {
  name: 'HTitle',
  mixins:[mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    }
  },
  data () {
    return {
      val: null
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        text: null,
        show: true
      }, this.config)
    },
    currentText:{
      get(){
        return this.elProps.text||this.val
      },
      set(){}
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
    }
  }
}
</script>
