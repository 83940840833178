<template>
  <van-col span="12">
    <div class="uploader">
      <van-uploader
        v-model="fileList"
        :max-count="elProps.limit"
        :upload-icon="elProps.icon"
        :accept="elProps.accept"
        :previewSize="elProps.previewSize"
        :uploadText='elProps.uploadText'
        multiple
        :after-read="handleFileUpload"
        @delete="handleDelete">
        <template #preview-cover="{ file }">
          <div v-if="file" class="preview-cover van-ellipsis">{{ file.name }}</div>
        </template>
        <van-image
          v-if="elProps.iconImgUrl"
          width="100%"
          height="100px"
          fit="contain"
          :src="elProps.iconImgUrl"
        >
        </van-image>
      </van-uploader>
      <div v-if="elProps.label" class="uploader-label"><span v-if="elProps.required">*</span>{{elProps.label}}</div>
    </div>
  </van-col>
</template>

<script>
// 引入请求
import { request } from '@/api/service'
import ImagesQuicklyCompress from 'images-quickly-compress'
export default {
  name: 'HUpload',
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => { }
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal || []
        this.fileList = newVal || []
      }
    }
  },
  data () {
    return {
      val:[],
      fileList: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        uploadText: '',
        size: 'mini',
        previewSize:null,
        limit: 1,
        icon: 'photograph',
        //accept: '.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pot,.pps,.vsd,.rtf,.wps,.et,.dps,.pdf,.txt,.jpg,.png,.jpeg,.jif',
        relateId: null,
        busiType: null,
        extraParam:{},
      }, this.config)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val || []
      this.fileList=this.config.val || []
      // {name:'名称',url:'https://gwdev.happysalers.com/afis-engine//dfs/storage/getFile/pri/FS-2021081720182991857835006290',isImage: true}
    },
    handleDelete(upLoadObj,data){
      this.val.splice(data.index,1);
    },
    async handleFileUpload (upLoadObj) {
      let { file } = upLoadObj
      let isLt5M = file.size / 1024 / 1024 < 5
      const suffixMap = {
        'image/tiff': '.tif',
        'application/x-301': '0.301',
        'application/x-906': '0.906',
        'application/x-a11': '.a11',
        'application/postscript': '.ai',
        'audio/aiff': '.aifc',
        'application/x-anv': '.anv',
        'video/x-ms-asf': '.asf',
        'video/avi': '.avi',
        'application/x-bot': '.bot',
        'application/x-c90': '.c90',
        'application/vnd.ms-pki.seccat': '.cat',
        'application/x-cdr': '.cdr',
        'application/x-x509-ca-cert': '.cer',
        'application/x-cgm': '.cgm',
        'java/*': '.class',
        'application/x-cmp': '.cmp',
        'application/x-cot': '.cot',
        'text/css': '.css',
        'application/x-dbf': '.dbf',
        'application/x-dbx': '.dbx',
        'application/x-dcx': '.dcx',
        'application/x-dgn': '.dgn',
        'application/msword': '.dot',
        'application/x-dwf': '.dwf',
        'application/x-dxb': '.dxb',
        'application/vnd.adobe.edn': '.edn',
        'message/rfc822': '.eml',
        'application/x-epi': '.epi',
        'application/x-msdownload': '.exe',
        'application/vnd.fdf': '.fdf',
        'application/x-g4': '.g4',
        'application/x-': '.',
        'application/x-gl2': '.gl2',
        'application/x-hgl': '.hgl',
        'application/x-hpgl': '.hpg',
        'application/mac-binhex40': '.hqx',
        'application/hta': '.hta',
        'text/html': '.htm',
        'text/webviewhtml': '.htt',
        'application/x-icb': '.icb',
        'application/x-ico': '.ico',
        'application/x-iphone': '.iii',
        'application/x-internet-signup': '.ins',
        'video/x-ivf': '.IVF',
        'application/x-jpe': '.jpe',
        'image/jpeg': '.jpeg',
        'image/jpg': '.jpg',
        'application/x-javascript': '.js',
        'audio/x-liquid-file': '.la1',
        'application/x-latex': '.latex',
        'application/x-lbm': '.lbm',
        'video/x-mpeg': '.m1v',
        'audio/mpegurl': '.m3u',
        'application/x-mac': '.mac',
        'application/x-mdb': '.mdb',
        'application/x-mi': '.mi',
        'audio/mid': '.midi',
        'audio/x-musicnet-stream': '.mns',
        'video/x-sgi-movie': '.movie',
        'audio/mp2': '.mp2',
        'audio/mp3': '.mp3',
        'video/x-mpg': '.mpa',
        'video/mpg': '.mpg',
        'application/vnd.ms-project': '.mpp',
        'video/mpeg': '.mpv2',
        'application/x-mmxp': '.mxp',
        'application/x-nrf': '.nrf',
        'text/x-ms-odc': '.odc',
        'application/pkcs10': '.p10',
        'application/x-pkcs7-certificates': '.p7b',
        'application/pkcs7-mime': '.p7m',
        'application/pkcs7-signature': '.p7s',
        'application/x-pci': '.pci',
        'application/x-pcx': '.pcx',
        'application/pdf': '.pdf',
        'application/x-pkcs12': '.pfx',
        'application/x-pic': '.pic',
        'application/x-perl': '.pl',
        'audio/scpls': '.pls',
        'image/png': '.png',
        'application/x-ppm': '.ppm',
        'application/vnd.ms-powerpoint': '.ppt',
        'application/x-pr': '.pr',
        'application/x-prn': '.prn',
        'application/x-ps': '.ps',
        'application/x-ptn': '.ptn',
        'text/vnd.rn-realtext3d': '.r3t',
        'audio/x-pn-realaudio': '.ram',
        'application/rat-file': '.rat',
        'application/vnd.rn-recording': '.rec',
        'application/x-rgb': '.rgb',
        'application/vnd.rn-realsystem-rjt': '.rjt',
        'application/x-rle': '.rle',
        'application/vnd.adobe.rmf': '.rmf',
        'application/vnd.rn-realsystem-rmj': '.rmj',
        'application/vnd.rn-rn_music_package': '.rmp',
        'application/vnd.rn-realmedia-vbr': '.rmvb',
        'application/vnd.rn-realplayer': '.rnx',
        'audio/x-pn-realaudio-plugin': '.rpm',
        'text/vnd.rn-realtext': '.rt',
        'application/x-rtf': '.rtf',
        'application/x-sam': '.sam',
        'application/sdp': '.sdp',
        'application/x-stuffit': '.sit',
        'application/x-sld': '.sld',
        'application/smil': '.smi',
        'application/x-smk': '.smk',
        'application/vnd.ms-pki.certstore': '.sst',
        'application/x-tdf': '.tdf',
        'application/x-tga': '.tga',
        'application/x-tif': '.tif',
        'application/x-bittorrent': '.torrent',
        'text/plain': '.txt',
        'text/iuls': '.uls',
        'application/x-vda': '.vda',
        'application/vnd.visio': '.vsdx',
        'application/x-vst': '.vst',
        'text/xml': '.xml',
        'audio/x-ms-wax': '.wax',
        'application/x-wb2': '.wb2',
        'image/vnd.wap.wbmp': '.wbmp',
        'application/x-wk3': '.wk3',
        'application/x-wkq': '.wkq',
        'video/x-ms-wm': '.wm',
        'application/x-ms-wmd': '.wmd',
        'text/vnd.wap.wml': '.wml',
        'video/x-ms-wmx': '.wmx',
        'application/x-wp6': '.wp6',
        'application/x-wpg': '.wpg',
        'application/x-wq1': '.wq1',
        'application/x-wri': '.wri',
        'application/x-ws': '.ws',
        'text/scriptlet': '.wsc',
        'video/x-ms-wvx': '.wvx',
        'application/vnd.adobe.xfdf': '.xfdf',
        'application/vnd.ms-excel': '.xls',
        'application/x-xlw': '.xlw',
        'application/x-x_b': '.x_b',
        'application/vnd.symbian.install': '.sisx',
        'application/vnd.iphone': '.ipa',
        'application/x-silverlight-app': '.xap	'
      }
      if (!isLt5M) {
        if (suffixMap[file.type] && ['.jpg', '.png', '.jpeg'].includes(suffixMap[file.type])) {
          // 超过5m的图片文件, 启用压缩
          const compressFiles = await new ImagesQuicklyCompress({
            mode: 'pixel', // 根据像素总大小压缩
            num: 1e6, // 压缩后图片的总像素都是100万（相当于1000px * 1000px的图片）
            size: '500kb', // 图片大小超过500kb执行压缩
            imageType: file.type, // jpeg压缩效果十分理想
            quality: 0.8,
            orientation: false
          }).compressor([file])
          isLt5M = compressFiles[0].size / 1024 / 1024 < 5
          file = new window.File(
            [compressFiles[0]], file.name, { type: compressFiles[0].type }
          )
        }
        if (!isLt5M) {
          Toast('请上传5M以下的文件')
          return
        }
      }
      // 通过 FormData 对象上传文件
      const formData = new FormData()
      formData.append('file', file)
      formData.append('busiType', this.elProps.busiType || 'productImage')
      formData.append('relateId', this.elProps.relateId)
      formData.append('category', 'public')
      upLoadObj.status='uploading'
      upLoadObj.message='上传中...'
      // 发起请求
      request({
        url: '/afis-engine/dfs/storage/upload',
        method: 'post',
        data: formData,
        headers: {
          'Access-Token': null,
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        upLoadObj.status='done'
        upLoadObj.message=''
        const fileObj = { fileId: res.fileId, filePath: res.fileUrl, fileName: file.name, fileType: file.type, fileSize: file.size, name:file.name, url:res.fileUrl, isImage: true }
        const resObj=Object.assign(fileObj,this.elProps.extraParam)
        this.val.push(resObj)
        this.$emit('change', this.val)
        this.$emit('input', this.val)
      }).catch(err => {
        upLoadObj.status='failed'
        upLoadObj.message='上传失败'
        Toast(err.message||'上传失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .uploader{
    // display: inline-block;
    text-align: center;
    margin: 0 5px;
    .uploader-label{
      font-size: 12px;
      color:#b7b7b7;
      padding: 8px;
      span{
        color: #ee0a24;
      }
    }
  }
  ::v-deep .van-uploader__preview{
    margin-bottom: 0;
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
</style>
