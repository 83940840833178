<template>
  <div>
    <HCascader :config="elProps" @input="inputEvent" @selected="selectedEvent"/>
  </div>
</template>
<script>
import HCascader from './HCascader.vue'
import areaJson from "@/assets/data/area";
import mixins from './mixins'
export default {
  name: 'HCascaderArea',
  mixins:[mixins],
  inheritAttrs: false,
  components: { HCascader },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        show: true,
        readonly: true,
        disabled: false,
        options: areaJson,
        valueKey:'value',
        labelKey:'label',
        childrenKey:'children',
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请选择'
      }, this.config)
    },
  },
  created () {
  },
  methods: {
    inputEvent(val){
      this.$emit('input', val)
    },
    selectedEvent(options){
      if(!(options&&options.length===3)){
        return false
      }
      this.form.provinceCode=options[0].value
      this.form.provinceName=options[0].label

      this.form.cityCode=options[1].value
      this.form.cityName=options[1].label

      this.form.countyCode=options[2].value
      this.form.countyName=options[2].label
    }
  }
}
</script>
