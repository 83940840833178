import { cloneDeep } from "lodash";
import MotorRisk from "../risk/motor";
import EnergyRisk from "../risk/energy";
import { request } from "@/api/service";
const config = {
  basis: (pageCtx, extdata) => {
    return {
      fmName: "基础信息",
      fmCode: "basis",
      show: true,
      formItems: [
        {
          prop: "plateNo",
          control: "HText",
          show: true,
          config: {
            label: "车牌号",
            val: "",
            placeholder: "",
            disabled: false,
            required: true,
          },
        },
        {
          prop: "frameNo",
          control: "HText",
          show: true,
          config: {
            label: "车架号",
            val: "",
            placeholder: "",
            disabled: false,
            required: true,
          },
        },
        {
          prop: "ownerName",
          control: "HText",
          show: true,
          config: {
            label: "车主姓名",
            val: "",
            placeholder: "",
            disabled: false,
            required: true,
          },
        },
        {
          prop: "orgName",
          control: "HText",
          show: true,
          config: {
            label: "出单机构",
            val: "",
            placeholder: "",
            disabled: false,
            required: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "出单机构必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "orgIdVal",
          control: "HCascader",
          show: true,
          config: {
            label: "出单机构",
            placeholder: "请选择出单机构",
            disabled: false,
            val: [pageCtx.$store.getters.info.issueOrgId],
            options: [],
            labelKey: 'name',
            valueKey: 'id',
            rules: [
              {
                type: "array",
                required: true,
                disabled: false,
                message: "出单机构必填",
                trigger: "onChange",
              },
            ],
            changeEvent: async (ctx) => {
              const { pageContext } = ctx
              pageCtx.pageForm.basis.dealerId = null
              pageCtx.pageForm.basis.issuerCode = null
              const dictData = await request({
                url: '/organization/org/tree',
                method: "post",
                data: {}
              })
              let copyObj = cloneDeep(pageCtx.pageForm)
              let len = pageCtx.pageForm.basis.orgIdVal.length - 1
              pageCtx.pageForm.basis.orgId = pageCtx.pageForm.basis.orgIdVal[len]
              let filters = dictData.filter(item => item.id === pageCtx.pageForm.basis.orgId)
              if (filters.length) {
                copyObj.basis.orgName = filters[0].name
              }
              pageCtx.$store.dispatch("car/setQuote", copyObj);
              pageContext.queryCompanyData()
              pageContext.queryIssuer()
              pageContext.getChannelData()
            }
          }
        },
        {
          prop: "dealerName",
          control: "HText",
          show: true,
          config: {
            label: "业务渠道",
            val: "",
            placeholder: "",
            disabled: false,
            required: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "业务渠道必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "dealerId",
          control: "HSelect",
          show: true,
          config: {
            label: "业务渠道",
            placeholder: "请选择业务渠道",
            disabled: false,
            val: null,
            apiCode: "",
            apiMethod: "post",
            options:[],
            rules: [],
            confirmEvent: (ctx) => {
              // const { pageContext } = ctx
              // pageCtx.pageForm.basis.issuerCode = null
              // let copyObj = cloneDeep(pageCtx.pageForm)
              // let filters = ctx.options.filter(item => item.id === pageCtx.pageForm.basis.dealerId)
              // if (filters.length) {
              //   copyObj.basis.dealerName = filters[0].label
              // }
              // pageCtx.$store.dispatch("car/setQuote", copyObj);
              // pageContext.queryCompanyData()
              // pageContext.queryIssuer()
            },
            cancelEvent: (ctx) => {
                // const { pageContext } = ctx
                pageCtx.pageForm.basis.dealerId = null
                pageCtx.pageForm.basis.dealerName = null
                // let copyObj = cloneDeep(pageCtx.pageForm)
                // pageCtx.$store.dispatch("car/setQuote", copyObj);
                // pageContext.queryCompanyData()
            }
          }
        },
        {
          prop: "issuerCode",
          control: "HSelect",
          show: true,
          config: {
            label: "出单人员",
            placeholder: "请选择出单人员",
            disabled: false,
            val: null,
            apiCode: "",
            apiMethod: "post",
            labelKey: "name",
            valueKey: "id",
            options:[],
            apiData: {
              orgId:
                pageCtx.quote &&
                pageCtx.quote.basis &&
                pageCtx.quote.basis.orgId,
            },
            rules: [
              {
                required: true,
                disabled: false,
                message: "出单人员必填",
                trigger: "onChange",
              },
            ],
            confirmEvent: (ctx) => {
              let filters = ctx.options.filter(item => item.id === pageCtx.pageForm.basis.issuerCode)
              if (filters.length) {
                let copyObj = cloneDeep(pageCtx.pageForm)
                copyObj.basis.issuerName = filters[0].name
                pageCtx.$store.dispatch("car/setQuote", copyObj);
              }
            }
          },
        },
        {
          prop: "insuredOrgCode",
          control: "HSelect",
          show: true,
          config: {
            label: "保险公司",
            placeholder: "请选择保险公司",
            disabled: false,
            val: null,
            dictCode: "INS_COMPANY",
            options:[],
            rules: [
              {
                required: true,
                disabled: false,
                message: "保险公司必填",
                trigger: "onChange",
              },
            ],
            confirmEvent: (ctx) => {
              const { pageContext, val } = ctx;
              if (val === 'YGBX') {
                  pageContext.initRiskVal(pageContext.pageRiskForm)
              }
              let copyObj = cloneDeep(pageCtx.pageForm)
              let filters = ctx.options.filter(item => item.value === pageCtx.pageForm.basis.insuredOrgCode)
              if (filters.length) {
                copyObj.basis.insuredOrgName = filters[0].label
                pageCtx.pageForm.basis.insuredOrgName = filters[0].label
              }
              pageCtx.$store.dispatch("car/setQuote", copyObj);
            }
          }
        },
        {
          prop: "quoteType",
          control: "HRadio",
          show: false,
          config: {
            label: "",
            placeholder: "请选择",
            disabled: false,
            val: "N",
            dictCode: "PAIC_QUOTE_TYPE",
            options:[],
            rules: [],
            confirmEvent: (ctx) => {}
          }
        },
        {
          prop: "bociVipFlag",
          control: "HSelect",
          show: false,
          config: {
            label: "VIP标志",
            placeholder: "请选择",
            disabled: false,
            val: "",
            dictCode: "BOCI_VIP_FLAG",
            options:[],
            rules: [],
            confirmEvent: (ctx) => {}
          }
        },
        {
          prop: "bociCustomerSource",
          control: "HSelect",
          show: false,
          config: {
            label: "客户来源",
            placeholder: "请选择",
            disabled: false,
            val: "",
            dictCode: "BOCI_CUSTOMER_SOURCE",
            options:[],
            rules: [],
            confirmEvent: (ctx) => {}
          }
        },
      ],
    };
  },
  compulsory: (pageCtx, extdata) => {
    return {
      fmName: "交强险",
      fmCode: "compulsory",
      show: true,
      labelWidth: "7em",
      formItems: [
        {
          prop: "startTime",
          control: "HDatePicker",
          show: true,
          config: {
            label: "保险生效期",
            placeholder: "请选择保险生效日期",
            disabled: false,
            val: (new Date()).getTime() + 86400000 - (((new Date().getHours()) * 60 * 60 * 1000) + ((new Date().getMinutes()) * 60 * 1000) + ((new Date().getSeconds()) * 1000)),
            minDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "保险生效日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "endTime",
          control: "HDatePicker",
          show: true,
          config: {
            label: "保险截止期",
            placeholder: "请选择保险截止日期",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "保险截止日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "taxType",
          control: "HSelect",
          show: true,
          config: {
            label: "纳税类型",
            placeholder: "请选择纳税类型",
            disabled: false,
            val: "3",
            dictCode: "TAX_VEHICLE_TYLE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "纳税类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "taxAgency",
          control: "HInput",
          show: false,
          config: {
            label: "税务机关名称",
            placeholder: "请输入税务机关名称",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "税务机关名称必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "taxCertificateNo",
          control: "HInput",
          show: false,
          config: {
            label: "减免凭证号",
            placeholder: "请输入减免凭证号",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "减免凭证号必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "taxDocumentDate",
          control: "HDatePicker",
          show: false,
          config: {
            label: "开具凭证日期",
            placeholder: "请选择开具凭证日期",
            disabled: false,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "开具凭证日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "taxAbateReason",
          control: "HSelect",
          show: false,
          config: {
            label: "减税原因",
            placeholder: "请选择减税原因",
            disabled: false,
            val: null,
            dictCode: "TAX_ABATE_REASON",
            rules: [
              {
                required: true,
                disabled: false,
                message: "减税原因必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "taxAbateType",
          control: "HSelect",
          show: false,
          config: {
            label: "减税方案",
            placeholder: "请选择减税方案",
            disabled: false,
            val: null,
            dictCode: "TAX_ABATE_TYPE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "减税方案必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "taxAbateProportion",
          control: "HInput",
          show: false,
          config: {
            label: "减税比例",
            placeholder: "请输入减税比例",
            disabled: false,
            val: null,
            append: "%",
            rules: [
              {
                required: true,
                disabled: false,
                message: "减税比例必填",
                trigger: "onBlur",
              },
              {
                pattern: /^\d\.([1-9]{1,2}|[0-9][1-9])$|^[1-9]\d{0,1}(\.\d{1,2}){0,1}$|^100(\.0{1,2}){0,1}$/,
                message: "减税比例格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "taxAbateAmount",
          control: "HInput",
          show: false,
          config: {
            label: "减税金额",
            placeholder: "请输入减税金额",
            disabled: false,
            val: null,
            append: "元",
            rules: [
              {
                required: true,
                disabled: false,
                message: "必填信息",
                trigger: "onBlur",
              },
              {
                pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                disabled: false,
                message: "减税金额格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
      ],
    };
  },
  commercial: (pageCtx, extdata) => {
    return {
      fmName: "商业险",
      fmCode: "commercial",
      show: true,
      labelWidth: "7em",
      formItems: [
        {
          prop: "startTime",
          control: "HDatePicker",
          show: true,
          config: {
            label: "保险生效期",
            placeholder: "请选择保险生效日期",
            disabled: false,
            val: (new Date()).getTime() + 86400000 - (((new Date().getHours()) * 60 * 60 * 1000) + ((new Date().getMinutes()) * 60 * 1000) + ((new Date().getSeconds()) * 1000)),
            minDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "保险生效日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "endTime",
          control: "HDatePicker",
          show: true,
          config: {
            label: "保险截止期",
            placeholder: "请选择保险截止日期",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "保险截止日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
      ],
      riskItems: null,
    };
  },
};
export default {
  scriptConfig: {
    adapterForm: (data) => {
      const { pageCtx, ctx } = data;
      const cloneform = cloneDeep(pageCtx.form);
      return cloneform;
    },
    submitBefore: async (data) => {
      return true;
    },
    submitAfter: (data) => {
      const { pageCtx, ctx, res } = data;
    },
    initAfter: async (data) => {
      const { pageCtx, res } = data;
    },
    initDetailAfter: (data) => {
      const { pageCtx, ctx, res } = data;
    },
  },
  addConfig: (pageCtx, extdata) => {
    const commercialLayout = config.commercial(pageCtx, extdata);
    if (extdata.newEnergyFlag === "1") {
      commercialLayout.riskItems = EnergyRisk(pageCtx, extdata);
    } else {
      commercialLayout.riskItems = MotorRisk(pageCtx, extdata);
    }
    return {
      basis: config.basis(pageCtx, extdata),
      compulsory: config.compulsory(pageCtx, extdata),
      commercial: commercialLayout,
    };
  },
  editConfig: (pageCtx, extdata) => {
    return {
      basis: config.basis(pageCtx),
      compulsory: config.compulsory(pageCtx),
      commercial: config.commercial(pageCtx),
    };
  },
  viewConfig: (pageCtx, extdata) => {
    return {
      basis: config.basis(pageCtx),
      compulsory: config.compulsory(pageCtx),
      commercial: config.commercial(pageCtx),
    };
  },
};
