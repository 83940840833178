import util from "../util";
import { mergeWith, keys } from "lodash";
const insuredOperator = function() {
  if (
    this.pageForm.insured.custType === "1" &&
    this.pageForm.holder.custType !== "1"
  ) {
    util.onShow(this.pageLayout.insured.operator);
    util.onShow(this.pageLayout.insured.operator_name);
    util.onShow(this.pageLayout.insured.operator_certificateType);
    util.onShow(this.pageLayout.insured.operator_certificateNo);
    util.onShow(this.pageLayout.insured.operator_mobile);
  } else {
    util.offShow(this.pageLayout.insured.operator);
    util.offShow(this.pageLayout.insured.operator_name);
    util.offShow(this.pageLayout.insured.operator_certificateType);
    util.offShow(this.pageLayout.insured.operator_certificateNo);
    util.offShow(this.pageLayout.insured.operator_mobile);
  }
};
const vehicle = {
  //是否新车
  watchPlateFlag: function(val) {
    if (val) {
      this.pageForm.vehicle.plateNo = null;
      util.onDisabled(this.pageLayout.vehicle.plateNo.config);
      util.offRequired(this.pageLayout.vehicle.plateNo.config);
      //来历凭证
      util.onRequired(this.pageLayout.vehicle.certificateType.config);
      util.onRequired(this.pageLayout.vehicle.certificateDate.config);
      util.onRequired(this.pageLayout.vehicle.certificateNo.config);
    } else {
      util.offDisabled(this.pageLayout.vehicle.plateNo.config);
      util.onRequired(this.pageLayout.vehicle.plateNo.config);
      //来历凭证
      util.offRequired(this.pageLayout.vehicle.certificateType.config);
      util.offRequired(this.pageLayout.vehicle.certificateDate.config);
      util.offRequired(this.pageLayout.vehicle.certificateNo.config);
    }
  },
  //是否过户车
  watchTransferFlag: function(val) {
    if (val === "1") {
      util.onShow(this.pageLayout.vehicle.transferDate);
    } else {
      util.offShow(this.pageLayout.vehicle.transferDate);
    }
  },
  //车辆使用性质
  watchVehicleUsage: function(val) {
    if (val === "1") {
      util.setDictCode(
        this.pageLayout.vehicle.vehicleUsageDetail.config,
        "VEHICLE_USAGE_DETAIL_PER"
      );
    } else {
      util.setDictCode(
        this.pageLayout.vehicle.vehicleUsageDetail.config,
        "VEHICLE_USAGE_DETAIL_PUB"
      );
    }
  },
  watchNewEnergyFlag: function(val) {
    if (val === '1') {
        util.offRequired(this.pageLayout.vehicle.displacement.config);
    } else {
        util.onRequired(this.pageLayout.vehicle.displacement.config);
    }
  },
  watchVehicleInspectionFlag: function(val) {
      let { insuredOrgCode } = this.pageForm.basis
    //   验车类型
      if (val === '1' && insuredOrgCode === 'PAIC') {
        util.onShow(this.pageLayout.vehicle.paicType)
      } else {
        util.offShow(this.pageLayout.vehicle.paicType)
      }
    //   验车时间
      if (val === '1' && insuredOrgCode === 'PAIC' || val === '4' && insuredOrgCode === 'PICC') {
        util.onShow(this.pageLayout.vehicle.inspectionTime)
      } else {
        util.offShow(this.pageLayout.vehicle.inspectionTime)
      }
    //   验车时间段
      if (val === '1' && insuredOrgCode === 'PAIC') {
        util.onShow(this.pageLayout.vehicle.inspectionTimePeriod)
      } else {
        util.offShow(this.pageLayout.vehicle.inspectionTimePeriod)
      }
    //   验车地点
      if (val === '1' && insuredOrgCode === 'PAIC') {
        util.onShow(this.pageLayout.vehicle.address)
      } else {
        util.offShow(this.pageLayout.vehicle.address)
      }
    //   验车人
      if (val === '1' && insuredOrgCode === 'GPIC' || val === '4' && insuredOrgCode === 'GPIC') {
        util.onShow(this.pageLayout.vehicle.inspector)
      } else {
        util.offShow(this.pageLayout.vehicle.inspector)
      }
  }
};
const owner = {
  watchOwner: function(newVal, oldVal) {
    if (!oldVal) {
      return false;
    }
    if (
      !(
        this.pageForm.holder.sameOwnerFlag ||
        this.pageForm.insured.sameOwnerFlag
      )
    ) {
      return false;
    }
    keys(newVal).forEach((key) => {
      // if (newVal[key] === oldVal[key]) {
      //   return false;
      // }
      if(key==="sameOwnerFlag"||key==="sameHolderFlag"){
        return false
      }
      //投保人
      if (
        this.pageForm.holder.sameOwnerFlag &&
        newVal[key] !== this.pageForm.holder[key]
      ) {
        this.pageForm.holder[key] = newVal[key];
      }
      //被保人
      if (
        this.pageForm.insured.sameOwnerFlag &&
        newVal[key] !== this.pageForm.insured[key]
      ) {
        this.pageForm.insured[key] = newVal[key];
      }
    });
  },
  watchCustType: function(val) {
    if (val === "1") {
      //公户
      util.company(this.pageLayout.owner);
    } else {
      //个人
      util.person(this.pageLayout.owner);
    }
  },
  watchCertificateNo: function(val) {
    util.execCertificateNo(val, this.pageForm.owner);
  },
};
const holder = {
  watchHolder: function(newVal, oldVal) {
    if (!oldVal) {
      return false;
    }
    if (!this.pageForm.insured.sameHolderFlag) {
      return false;
    }
    keys(newVal).forEach((key) => {
      // if (newVal[key] === oldVal[key]) {
      //   return false;
      // }
      if(key==="sameOwnerFlag"||key==="sameHolderFlag"){
        return false
      }
      //被保人
      if (
        this.pageForm.insured.sameHolderFlag &&
        newVal[key] !== this.pageForm.insured[key]
      ) {
        this.pageForm.insured[key] = newVal[key];
      }
    });
  },
  //投保人同车主
  watchSameOwnerFlag: function(val) {
    if (val === true) {
      util.onDisabledForm(this.pageLayout.holder, [
        "operator",
        "operator_name",
        "operator_certificateType",
        "operator_certificateNo",
        "operator_mobile",
      ]);
      // operator_certificateType
      mergeWith(this.pageForm.holder, this.pageForm.owner,(objValue, srcValue, key) => {
        if (key === "sameOwnerFlag"||key === "sameHolderFlag") {
          return objValue;
        }
      });
      // 投保联系人信息证件类型默认值修改
      this.pageForm.holder.operator_certificateType = this.pageForm.holder.custType === '1' ? '1' : this.pageForm.holder.operator_certificateType
      this.$store.dispatch("car/setQuote", this.pageForm);
    } else {
      util.offDisabledForm(this.pageLayout.holder);
    }
  },
  watchCustType: function(val) {
    if (val === "1") {
      //公户
      util.company(this.pageLayout.holder);
      util.onShow(this.pageLayout.holder.operator);
      util.onShow(this.pageLayout.holder.operator_name);
      util.onShow(this.pageLayout.holder.operator_certificateType);
      util.onShow(this.pageLayout.holder.operator_certificateNo);
      util.onShow(this.pageLayout.holder.operator_mobile);
    } else {
      //个人
      util.person(this.pageLayout.holder);
      util.offShow(this.pageLayout.holder.operator);
      util.offShow(this.pageLayout.holder.operator_name);
      util.offShow(this.pageLayout.holder.operator_certificateType);
      util.offShow(this.pageLayout.holder.operator_certificateNo);
      util.offShow(this.pageLayout.holder.operator_mobile);
    }
  },
  watchCertificateNo: function(val) {
    util.execCertificateNo(val, this.pageForm.holder);
  },
};
const insured = {
  //被保人同车主
  watchSameOwnerFlag: function(val) {
    if (val === true) {
      this.pageForm.insured.sameHolderFlag = false;
      util.onDisabledForm(this.pageLayout.insured, [
        "operator",
        "operator_name",
        "operator_certificateType",
        "operator_certificateNo",
        "operator_mobile",
      ]);
      mergeWith(this.pageForm.insured, this.pageForm.owner,(objValue, srcValue, key) => {
        if (key === "sameOwnerFlag"||key === "sameHolderFlag") {
          return objValue;
        }
      });
      this.$store.dispatch("car/setQuote", this.pageForm);
      return false;
    }
    if (
      !(
        this.pageForm.insured.sameHolderFlag ||
        this.pageForm.insured.sameOwnerFlag
      )
    ) {
      util.offDisabledForm(this.pageLayout.insured);
    }
  },
  //被保人同投保人
  watchSameHolderFlag: function(val) {
    if (val === true) {
      this.pageForm.insured.sameOwnerFlag = false;
      util.onDisabledForm(this.pageLayout.insured, [
        "operator",
        "operator_name",
        "operator_certificateType",
        "operator_certificateNo",
        "operator_mobile",
      ]);
      mergeWith(
        this.pageForm.insured,
        this.pageForm.holder,
        (objValue, srcValue, key) => {
          if (key === "sameOwnerFlag"||key === "sameHolderFlag") {
            return objValue;
          }
        }
      );
      this.$store.dispatch("car/setQuote", this.pageForm);
      return false;
    }
    if (
      !(
        this.pageForm.insured.sameHolderFlag ||
        this.pageForm.insured.sameOwnerFlag
      )
    ) {
      util.offDisabledForm(this.pageLayout.insured);
    }
  },
  watchCustType: function(val) {
    if (val === "1") {
      //公户
      util.company(this.pageLayout.insured);
    } else {
      //个人
      util.person(this.pageLayout.insured);
    }
  },
  watchCertificateNo: function(val) {
    util.execCertificateNo(val, this.pageForm.insured);
  },
};
const basis = {
    watchInsuredOrgCode: function(val) {
        let { vehicleInspectionFlag } = this.pageForm.vehicle
        //   验车类型
          if (vehicleInspectionFlag === '1' && val === 'PAIC') {
            util.onShow(this.pageLayout.vehicle.paicType)
          } else {
            util.offShow(this.pageLayout.vehicle.paicType)
          }
        //   验车时间
          if (vehicleInspectionFlag === '1' && val === 'PAIC' || vehicleInspectionFlag === '4' && val === 'PICC') {
            util.onShow(this.pageLayout.vehicle.inspectionTime)
          } else {
            util.offShow(this.pageLayout.vehicle.inspectionTime)
          }
        //   验车时间段
          if (vehicleInspectionFlag === '1' && val === 'PAIC') {
            util.onShow(this.pageLayout.vehicle.inspectionTimePeriod)
          } else {
            util.offShow(this.pageLayout.vehicle.inspectionTimePeriod)
          }
        //   验车地点
          if (vehicleInspectionFlag === '1' && val === 'PAIC') {
            util.onShow(this.pageLayout.vehicle.address)
          } else {
            util.offShow(this.pageLayout.vehicle.address)
          }
        //   验车人
          if (vehicleInspectionFlag === '1' && val === 'GPIC' || vehicleInspectionFlag === '4' && val === 'GPIC') {
            util.onShow(this.pageLayout.vehicle.inspector)
          } else {
            util.offShow(this.pageLayout.vehicle.inspector)
          }
    }
}
export default {
  computed: {},
  watch: {
    "pageForm.vehicle.plateFlag": function(val) {
      vehicle.watchPlateFlag.call(this, val);
    },
    "pageForm.vehicle.transferFlag": function(val) {
      vehicle.watchTransferFlag.call(this, val);
    },
    "pageForm.vehicle.vehicleUsage": function(val) {
      vehicle.watchVehicleUsage.call(this, val);
    },
    "pageForm.owner.custType": function(val) {
      owner.watchCustType.call(this, val);
    },
    "pageForm.owner.certificateNo": function(val) {
      owner.watchCertificateNo.call(this, val);
    },
    "pageForm.owner": {
      handler(newVal, oldVal) {
        owner.watchOwner.call(this, newVal, oldVal);
      },
      deep: true,
    },
    "pageForm.holder.sameOwnerFlag": function(val) {
      holder.watchSameOwnerFlag.call(this, val);
    },
    "pageForm.holder.custType": function(val) {
      holder.watchCustType.call(this, val);
      //是否显示被保人个人信息
      insuredOperator.call(this, val);
    },
    "pageForm.holder.certificateNo": function(val) {
      holder.watchCertificateNo.call(this, val);
    },
    "pageForm.holder": {
      handler(newVal, oldVal) {
        holder.watchHolder.call(this, newVal, oldVal);
      },
      deep: true,
    },
    "pageForm.insured.sameHolderFlag": function(val) {
      insured.watchSameHolderFlag.call(this, val);
    },
    "pageForm.insured.sameOwnerFlag": function(val) {
      insured.watchSameOwnerFlag.call(this, val);
    },
    "pageForm.insured.custType": function(val) {
      insured.watchCustType.call(this, val);
      //是否显示被保人个人信息
      insuredOperator.call(this, val);
    },
    "pageForm.insured.certificateNo": function(val) {
      insured.watchCertificateNo.call(this, val);
    },
    "pageForm.vehicle.newEnergyFlag": function(val) {
        vehicle.watchNewEnergyFlag.call(this, val);
    },
    "pageForm.vehicle.vehicleInspectionFlag": function(val) {
        vehicle.watchVehicleInspectionFlag.call(this, val);
    },
    "pageForm.basis.insuredOrgCode": function(val) {
        basis.watchInsuredOrgCode.call(this, val);
    }
  },
  methods: {
    required(config) {
      if (!config.rules) {
        return false;
      }
      const some = config.rules.some((item) => item.required && !item.disabled);
      return some;
    },
    rules(config) {
      if (!config.rules) {
        return [];
      }
      const ruleFilter = config.rules.filter((item) => !item.disabled);
      return ruleFilter;
    },
    openVehicleModel() {
      this.$refs.vehicleModelRef.open(this.pageForm && this.pageForm.vehicle);
    },
    selectModel(model) {
      Object.assign(this.pageForm.vehicle, model);
    },
  },
};
