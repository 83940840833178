const util = {
}
/**
 * 根据身份证号获得出生日期
 * @param {身份证号} inCardNo
 */
util.getBirthDay = (inCardNo) => {
  let birthdayno, birthdaytemp
  if (inCardNo && inCardNo.length === 18) {
    birthdayno = inCardNo.substring(6, 14)
  } else if (inCardNo && inCardNo.length === 15) {
    birthdaytemp = inCardNo.substring(6, 12)
    birthdayno = '19' + birthdaytemp
  } else {
    return false
  }
  const birthday =
    birthdayno.substring(0, 4) +
    '-' +
    birthdayno.substring(4, 6) +
    '-' +
    birthdayno.substring(6, 8)
  return birthday
}
/**
 * 根据身份证号获得性别
 * @param {身份证号} inCardNo
 */
util.getGender = (inCardNo) => {
  var sex = '1'
  if (inCardNo && inCardNo.length === 18) {
    sex = parseInt(inCardNo.substr(16, 1)) % 2 === 1 ? '1' : '2'
  } else if (inCardNo && inCardNo.length === 15) {
    sex = parseInt(inCardNo.substr(14, 1)) % 2 === 1 ? '1' : '2'
  }
  return sex
}
util.buildtree = (list, options = {}) => {
  const {
    keyField = 'id',
    childField = 'children',
    parentField = 'parentId'
  } = options

  const tree = []
  const record = {}

  if (list.length && list.length === 1) {
    if (list[0].root !== undefined && list[0].root === true) {
      list[0].children = []
      delete list[0].root
      tree.push(list[0])
      return tree
    }
  }
  for (let i = 0, len = list.length; i < len; i++) {
    const item = list[i]
    const id = item[keyField]

    if (!id) {
      continue
    }

    if (record[id]) {
      item[childField] = record[id]
    } else {
      item[childField] = record[id] = []
    }
    if (item[parentField] && (item.root === undefined || item.root === false)) {
      const parentId = item[parentField]

      if (!record[parentId]) {
        record[parentId] = []
      }
      delete item.root
      record[parentId].push(item)
    } else {
      delete item.root
      tree.push(item)
    }
  }
  return tree
}
// 数组上移
util.upMove = (list, index) => {
  list[index - 1] = list.splice(index, 1, list[index - 1])[0]
}
// 数组下移
util.downMove = (list, index) => {
  list[index] = list.splice(index + 1, 1, list[index])[0]
}
util.treeFind = (tree, fv) => {
  for (const data of tree) {
    if (data.value === fv) return data.label
    if (data.children) {
      const res = util.treeFind(data.children, fv)
      if (res) return res
    }
  }
  return null
}
export default util
