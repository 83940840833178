<template>
  <div>
    <van-field
      v-model="currentText"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :disabled="elProps.disabled"
      :readonly="elProps.readonly"
      :required="required"
      :rules="rules"
      :inputAlign="elProps.inputAlign"
      is-link
      @click="openPopup">
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-picker
          ref="picker"
          show-toolbar
          :title="elProps.label"
          :columns="options"
          :value-key="elProps.labelKey"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        >
        </van-picker>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { request } from '@/api/service'
import { findIndex, isArray } from 'lodash'
import mixins from './mixins'
export default {
  name: 'HSelect',
  mixins:[mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config.val': {
      handler (newVal, oldVal) {
        this.val = newVal
      }
    },
    'config.dictCode': {
      handler (newVal, oldVal) {
        if(newVal){
          this.initDict(newVal)
        }
      }
    },
    'config.options': {
      handler (newVal, oldVal) {
        this.options = newVal
      }
    }
  },
  data () {
    return {
      pageData:{
        show:false
      },
      val: null,
      options: []
    }
  },
  computed: {
    elProps () {
      return Object.assign({
        val: null,
        show: true,
        readonly: true,
        disabled: false,
        options: [],
        valueKey:'value',
        labelKey:'label',
        clearable: true,
        blurEvent: null,
        changeEvent: null,
        placeholder: '请选择'
      }, this.config)
    },
    currentText:{
      get(){
        if(!(this.val&&this.options&&this.options.length>0)){
          return null
        }
        const textOption=this.options.filter(item=>{return item[this.elProps.valueKey] === this.val})
        if(textOption && textOption.length>0){
          return textOption[0][this.elProps.labelKey]
        }else{
          return null
        }
      },
      set(){

      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.val = this.config.val
      if (this.config.dictCode) {
        this.initDict(this.config.dictCode)
      } else if (this.config.apiCode) {
        request({ url: this.config.apiCode, method: this.config.apiMethod || 'get', data: this.config.apiData })
          .then(res => {
            if (isArray(res)) {
              this.$set(this, 'options', res || [])
              this.config.options=res || []
            }
          })
      } else{
        this.$set(this, 'options', this.elProps.options)
      }
    },
    initDict(dictCode){
      this.$set(this, 'options', this.pageContext.dictMap[dictCode])
    },
    handleEvent (eventType) {
      const event = this.config[eventType + 'Event']
      if (event) {
        if (typeof event=== 'string' || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this)
        } else if (event instanceof Function) {
          event(this)
        }
      }
    },
    openPopup(){
      if(this.elProps.disabled){
        return false
      }
      this.pageData.show=true
      const index=findIndex(this.options,[this.elProps.valueKey, this.val])
      this.$nextTick(()=>{
        this.$refs.picker.setColumnIndex(0,index)
      })
    },
    handleConfirm(data){
      this.pageData.show=false
      if(!data){
        return false
      }
      this.val=data[this.elProps.valueKey]
      //this.currentText=data[this.elProps.labelKey]
      this.config.val = this.val
      this.$emit('input', this.val)
      this.handleEvent('confirm')
    },
    handleCancel(){
      this.pageData.show=false
      this.handleEvent('cancel')
    }
  }
}
</script>
