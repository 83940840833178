import { request } from "@/api/service";
import areaJson from "@/assets/data/area";
import dayjs from "dayjs";
const validator = {
  certificateNo: (obj, value) => {
    if (
      value &&
      obj.certificateType === "1" &&
      !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
        value
      )
    ) {
      return false;
    } else {
      return true;
    }
  },
  operatorCertificateNo: (obj, value) => {
    if (
      value &&
      obj.operator_certificateType === "1" &&
      !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
        value
      )
    ) {
      return false;
    } else {
      return true;
    }
  },
  pattern: (obj, value, patternStr) => {
    if (value && !patternStr.test(value)) {
      return false;
    } else {
      return true;
    }
  },
};
const config = {
  vehicle: (pageCtx, pageForm, pageLayout) => {
    return {
      fmName: "车辆",
      fmCode: "vehicle",
      show: true,
      labelWidth: "7em",
      formItems: [
        {
          prop: "plateNo",
          control: "HInput",
          show: true,
          config: {
            label: "车牌号",
            placeholder: "请输入车牌号",
            disabled: false,
            maxlength: 50,
            val: null,
            toUpperCase: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "车牌号必填",
                trigger: "onBlur",
              },
            ]
          },
        },
        {
          prop: "frameNo",
          control: "HInput",
          show: true,
          config: {
            label: "车架号",
            placeholder: "请输入车架号",
            disabled: false,
            maxlength: 17,
            toUpperCase: true,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "车架号必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^[a-zA-Z0-9]{17}$/,
                message: "车架号格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "engineNo",
          control: "HInput",
          show: true,
          config: {
            label: "发动机号",
            placeholder: "请输入发动机号",
            disabled: false,
            maxlength: 50,
            toUpperCase: true,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "发动机号必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^[a-zA-Z0-9]+$/,
                message: "发动机号格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "brandModel",
          control: "HText",
          show: false,
          config: {
            label: "车型",
            placeholder: "请选择车型",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "车型详情必选",
                trigger: "onBlur",
              },
            ],
            clickEvent: (ctx) => {},
          },
        },
        {
          prop: "modelDesc",
          control: "HField",
          show: true,
          config: {
            label: "车型详情",
            placeholder: "请选择车型详情",
            type: "textarea",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "车型详情必选",
                trigger: "onBlur",
              },
            ],
            clickEvent: (ctx) => {},
          },
        },
        {
          prop: "purchasePrice",
          control: "HInput",
          show: true,
          config: {
            label: "新车购置价",
            placeholder: "请输入新车购置价",
            disabled: false,
            maxlength: 12,
            type: "number",
            append: "元",
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "新车购置价必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "新车购置价格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          control: "HTitle",
          show: true,
          config: {
            text: "",
            style: {
              padding: "5px",
              backgroundColor: "#f7f8fa",
            },
          },
        },
        {
          prop: "registerDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "初登日期",
            placeholder: "请选择初登日期",
            disabled: false,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "初登日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "issueCardDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "发证日期",
            placeholder: "请选择发证日期",
            disabled: false,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "发证日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "loanVehicleFlag",
          control: "HRadio",
          show: true,
          config: {
            label: "是否贷款车",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "0",
            dictCode: "COMMON_IF",
            rules: [
              {
                required: true,
                disabled: false,
                message: "是否贷款车必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateType",
          control: "HSelect",
          show: true,
          config: {
            label: "来历凭证类型",
            placeholder: "请选择来历凭证类型",
            disabled: false,
            val: "01",
            dictCode: "VEHICLE_CERTIFICATE_TYPE",
            rules: [
              {
                required: true,
                disabled: true,
                message: "来历凭证类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "来历凭证日期",
            placeholder: "请选择来历凭证日期",
            disabled: false,
            maxDate: new Date(),
            val: null,
            rules: [
              {
                required: true,
                disabled: true,
                type: "number",
                message: "来历凭证类型必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "certificateNo",
          control: "HInput",
          show: true,
          config: {
            label: "来历凭证编号",
            placeholder: "请输入来历凭证编号",
            disabled: false,
            maxlength: 17,
            val: null,
            rules: [
              {
                required: true,
                disabled: true,
                message: "来历凭证类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "plateType",
          control: "HSelect",
          show: true,
          config: {
            label: "号牌种类",
            placeholder: "请选择号牌种类",
            disabled: false,
            val: "02",
            dictCode: "VEHICLE_PLATE_TYPE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "号牌种类必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "plateColor",
          control: "HSelect",
          show: true,
          config: {
            label: "号牌底色",
            placeholder: "请选择号牌底色",
            disabled: false,
            val: "1",
            dictCode: "VEHICLE_PLATE_COLOUR",
            rules: [
              {
                required: true,
                disabled: false,
                message: "号牌底色必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "driveArea",
          control: "HSelect",
          show: true,
          config: {
            label: "行驶区域",
            placeholder: "请选择行驶区域",
            disabled: false,
            val: "2",
            dictCode: "VEHICLE_DRIVE_AREA",
            rules: [
              {
                required: true,
                disabled: false,
                message: "行驶区域必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "vehicleKind",
          control: "HSelect",
          show: true,
          config: {
            label: "车辆种类",
            placeholder: "请选择车辆种类",
            disabled: false,
            val: "K33",
            dictCode: "VEHICLE_KIND",
            rules: [
              {
                required: true,
                disabled: false,
                message: "车辆种类必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "vehicleUsage",
          control: "HSelect",
          show: true,
          config: {
            label: "车辆使用性质",
            placeholder: "请选择车辆使用性质",
            disabled: false,
            val: "2",
            dictCode: "CS_USE_NATURE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "车辆使用性质必填",
                trigger: "onBlur",
              },
            ],
            confirmEvent: (ctx) => {
                const { pageContext } = ctx
                if (pageContext.$route.path === '/miniprogram/quote/normal/base') {
                    pageContext.initRiskVal(pageContext.$refs.riskFormRef.pageRiskForm)
                }
            }
          },
        },
        {
          prop: "vehicleUsageDetail",
          control: "HSelect",
          show: true,
          config: {
            label: "使用性质细分",
            placeholder: "请选择使用性质细分",
            disabled: false,
            val: "01",
            dictCode: "VEHICLE_USAGE_DETAIL_PUB",
            rules: [
              {
                required: true,
                disabled: false,
                message: "使用性质细分必填",
                trigger: "onBlur",
              },
            ],
            confirmEvent: (ctx) => {
                const { pageContext } = ctx
                if (pageContext.$route.path === '/miniprogram/quote/normal/base') {
                    pageContext.initRiskVal(pageContext.$refs.riskFormRef.pageRiskForm)
                }
            }
          },
        },
        {
          prop: "fuelType",
          control: "HSelect",
          show: true,
          config: {
            label: "燃料类型",
            placeholder: "请选择燃料类型",
            disabled: false,
            val: "A",
            dictCode: "VEHICLE_FUEL_TYPE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "燃料类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        // 控制新能源
        {
          prop: "newEnergyFlag",
          control: "HRadio",
          show: true,
          config: {
            label: "是否新能源",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "0",
            dictCode: "COMMON_IF",
            rules: [
              {
                required: true,
                disabled: false,
                message: "是否新能源必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "actualValue",
          control: "HInput",
          show: false,
          config: {
            label: "实际价值",
            placeholder: "请输入实际价值",
            disabled: false,
            maxlength: 12,
            type: "number",
            append: "元",
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "实际价值必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "实际价值格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "currentValue",
          control: "HInput",
          show: false,
          config: {
            label: "协商价值",
            placeholder: "请输入协商价值",
            disabled: false,
            maxlength: 12,
            type: "number",
            append: "元",
            val: 0,
            rules: [
              {
                disabled: false,
                pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "协商价值格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "displacement",
          control: "HInput",
          show: true,
          config: {
            label: "排量",
            placeholder: "请输入排量",
            disabled: false,
            maxlength: 20,
            type: "number",
            append: "T/L",
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "排量必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^\d*.?\d*$/,
                message: "排量格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "seatCount",
          control: "HInput",
          show: true,
          config: {
            label: "载客量",
            placeholder: "请输入载客量",
            disabled: false,
            maxlength: 20,
            type: "number",
            val: 5,
            rules: [
              {
                required: true,
                disabled: false,
                message: "载客量必填",
                trigger: "onBlur",
              },
            ],
            changeEvent: (ctx) => {
                const { pageContext } = ctx
                if (pageContext.$route.path === '/miniprogram/quote/normal/base') {
                    pageContext.initRiskVal(pageContext.$refs.riskFormRef.pageRiskForm)
                }
            }
          },
        },
        {
          prop: "carryingWeight",
          control: "HInput",
          show: true,
          config: {
            label: "核定载质量",
            placeholder: "请输入核定载质量",
            disabled: false,
            maxlength: 12,
            type: "number",
            append: "kg",
            val: null,
            rules: [
              {
                disabled: false,
                pattern: /^(([0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "核定载质量格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "emptyWeight",
          control: "HInput",
          show: true,
          config: {
            label: "整备质量",
            placeholder: "请输入整备质量",
            disabled: false,
            maxlength: 12,
            // type: "number",
            append: "kg",
            val: null,
            rules: [
              {
                disabled: false,
                pattern: /^(([0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "整备质量格式有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "sendhandCarFlag",
          control: "HSelect",
          show: true,
          config: {
            label: "二手车",
            placeholder: "请选择",
            disabled: false,
            val: "0",
            dictCode: "COMMON_IF",
            options:[],
            rules: [{
              required: true,
              message: "二手车必填",
              trigger: "onBlur",
            }],
            confirmEvent: (ctx) => {}
          }
        },
        {
          prop: "transferFlag",
          control: "HRadio",
          show: true,
          config: {
            label: "过户车",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "0",
            dictCode: "COMMON_IF",
            rules: [
              {
                required: true,
                disabled: false,
                message: "过户车必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "transferDate",
          control: "HDatePicker",
          show: false,
          config: {
            label: "过户日期",
            placeholder: "请选择过户日期",
            disabled: false,
            maxDate: new Date(),
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                type: "number",
                message: "过户日期必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
            prop: "ecdemicVehicleFlag",
            control: "HRadio",
            show: true,
            config: {
              label: "外地车标识",
              disabled: false,
              checkedColor: "#57ccc2",
              iconSize: "16px",
              val: "0",
              dictCode: "COMMON_IF",
              rules: []
            },
        },
        {
          prop: "mileage",
          control: "HInput",
          show: true,
          config: {
            label: "平均行驶里程",
            placeholder: "请输入平均行驶里程",
            disabled: false,
            required: false,
            maxlength: 8,
            type: "number",
            append: "公里",
            val: null,
            rules: [
              {
                pattern: /^$|^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                message: "平均行驶里程格式有误",
                trigger: "onBlur",
              },
            ],
          }
        },
        {
            prop: "vehicleInspectionFlag",
            control: "HSelect",
            show: true,
            config: {
              label: "验车情况",
              placeholder: "请选择验车情况",
              disabled: false,
              val: null,
              dictCode: "CHECK_CAR_STATUS",
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "验车情况必填",
                  trigger: "onBlur",
                },
              ],
            },
          },
          {
            prop: "paicType",
            control: "HSelect",
            show: false,
            config: {
              label: "验车类型",
              placeholder: "请选择验车类型",
              disabled: false,
              val: null,
              dictCode: "PAIC_CHECK_CAR_TYPE",
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "验车类型必填",
                  trigger: "onBlur",
                },
              ],
            },
          },
          {
            prop: "inspectionTime",
            control: "HDatePicker",
            show: false,
            config: {
              label: "验车时间",
              placeholder: "请选择验车时间",
              disabled: false,
              val: null,
              maxDate: new Date(),
              rules: [
                {
                  required: true,
                  disabled: false,
                  type: "number",
                  message: "验车时间必填",
                  trigger: "onChange",
                },
              ],
            },
          },
          {
            prop: "inspectionTimePeriod",
            control: "HSelect",
            show: false,
            config: {
              label: "验车时间段",
              placeholder: "请选择验车时间段",
              disabled: false,
              val: null,
              dictCode: "CHECK_CAR_TIME_PERIOD",
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "验车时间段必填",
                  trigger: "onBlur",
                },
              ],
            },
          },
          {
            prop: "address",
            control: "HInput",
            show: false,
            config: {
              label: "验车地点",
              placeholder: "请输入验车地点",
              disabled: false,
              maxlength: 50,
              toUpperCase: true,
              val: null,
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "验车地点必填",
                  trigger: "onBlur",
                }
              ],
            },
          },
          {
            prop: "inspector",
            control: "HInput",
            show: false,
            config: {
              label: "验车人",
              placeholder: "请输入验车人",
              disabled: false,
              maxlength: 30,
              toUpperCase: true,
              val: null,
              rules: [
                {
                  required: true,
                  disabled: false,
                  message: "验车人必填",
                  trigger: "onBlur",
                }
              ],
            },
          }
      ],
    };
  },
  owner: (pageCtx, pageForm, pageLayout) => {
    return {
      fmName: "车主",
      fmCode: "owner",
      show: true,
      formChange: (ctx) => {},
      formItems: [
        {
          prop: "custType",
          control: "HRadio",
          show: true,
          config: {
            label: "客户性质",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "0",
            dictCode: "CUST_TYPE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "客户性质必填",
                trigger: "onBlur",
              },
            ],
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              if (val === '1') {
                pageContext.pageForm.owner.certificateType = '6'
              } else {
                pageContext.pageForm.owner.certificateType = '1'
              }
            }
          },
        },
        {
          prop: "name",
          control: "HInput",
          show: true,
          config: {
            label: "车主姓名",
            placeholder: "请输入车主姓名",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "车主姓名必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateType",
          control: "HSelect",
          show: true,
          config: {
            label: "证件类型",
            placeholder: "请选择证件类型",
            disabled: false,
            val: "1",
            dictCode: "CERTIFICATE_TYPE_PER",
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateNo",
          control: "HInput",
          show: true,
          config: {
            label: "证件号码",
            placeholder: "请输入证件号码",
            disabled: false,
            val: null,
            maxlength: 18,
            toUpperCase: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件号码必填",
                trigger: "onBlur",
              },
              {
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  return validator.certificateNo(pageForm["owner"], val);
                },
                disabled: false,
                message: "证件号码格式输入有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certLongFlag",
          control: "HRadio",
          show: true,
          config: {
            label: "是否长期标识",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            labelWidth: "7em",
            val: "0",
            dictCode: "COMMON_IF",
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              if (val === '1') {
                pageContext.pageForm.owner.certBeginDate = new Date().getTime()
                pageContext.pageForm.owner.certEndDate = new Date("2099-01-01").getTime()
              } else {
                pageContext.pageForm.owner.certEndDate = null
                pageContext.pageForm.owner.certBeginDate = null
              }
            }
          }
        },
        {
          prop: "certBeginDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "证件有效起期",
            placeholder: "请选择证件有效起期",
            labelWidth: "7em",
            disabled: false,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件有效起期必填",
                trigger: "onChange",
                type: "number",
              }
            ],
          },
        },
        {
          prop: "certEndDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "证件有效止期",
            placeholder: "请选择证件有效止期",
            labelWidth: "7em",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件有效止期必填",
                trigger: "onChange",
                type: "number",
              }
            ],
          },
        },
        {
          prop: "gender",
          control: "HRadio",
          show: true,
          config: {
            label: "性别",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "1",
            dictCode: "GENDER",
            rules: [],
          },
        },
        {
          prop: "birthday",
          control: "HDatePicker",
          show: true,
          config: {
            label: "出生日期",
            placeholder: "请选择出生日期",
            disabled: false,
            val: null,
            minDate: dayjs()
              .subtract(100, "year")
              .toDate(),
            maxDate: new Date(),
            rules: [],
          },
        },
        {
          prop: "issuer",
          control: "HInput",
          show: true,
          config: {
            label: "证件签发机关",
            placeholder: "请输入证件签发机关",
            labelWidth: "7em",
            disabled: false,
            val: null,
          },
        },
        {
          control: "HTitle",
          show: true,
          config: {
            text: "",
            style: {
              padding: "5px",
              backgroundColor: "#f7f8fa",
            },
          },
        },
        {
          prop: "mobile",
          control: "HInput",
          show: true,
          config: {
            label: "手机号码",
            placeholder: "请输入手机号码",
            disabled: false,
            type: "number",
            maxlength: 11,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "手机号码必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                message: "手机号格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
        {
          prop: "landlineNumber",
          control: "HInput",
          show: false,
          config: {
            label: "座机",
            placeholder: "请输入座机",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "座机必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^(1[3-9]\d{9})|((0[0-9]{2}[-]*[0-9]{8})|(0[0-9]{3}[-]*[0-9]{7}))$/,
                message: "座机格式有误",
              },
            ],
          },
        },
        {
          prop: "email",
          control: "HInput",
          show: true,
          config: {
            label: "邮箱",
            placeholder: "请输入邮箱",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "邮箱必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "areaCodes",
          control: "HCascader",
          show: true,
          config: {
            label: "所在地区",
            placeholder: "请选择所在地区",
            disabled: false,
            val: null,
            options: areaJson,
            rules: [
              {
                type: "array",
                required: true,
                disabled: false,
                message: "所在地区必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "address",
          control: "HInput",
          show: true,
          config: {
            label: "详细地址",
            placeholder: "请输入详细地址",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "详细地址必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "ethnicGroup",
          control: "HSelect",
          show: true,
          config: {
            label: "民族",
            placeholder: "请选择民族",
            disabled: false,
            val: null,
            dictCode: "ETHNIC_GROUP",
          },
        },
        {
          prop: "homeAddress",
          control: "HInput",
          show: true,
          config: {
            label: "户籍地址",
            placeholder: "请输入户籍地址",
            disabled: false,
            val: null,
            rules: [
                {
                  required: true,
                  disabled: false,
                  message: "户籍地址必填",
                  trigger: "onBlur",
                }
            ]
          },
        },
        {
          prop: "postcode",
          control: "HInput",
          show: true,
          config: {
            label: "邮编",
            placeholder: "请输入邮编",
            type: "number",
            disabled: false,
            val: null,
            maxlength: 6,
            rules: [
              {
                disabled: false,
                message: "邮编格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^\d{6}$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
      ],
    };
  },
  holder: (pageCtx, pageForm, pageLayout) => {
    return {
      fmName: "投保人",
      fmCode: "holder",
      show: true,
      disabled: false,
      formChange: (ctx) => {},
      formItems: [
        {
          prop: "custType",
          control: "HRadio",
          show: true,
          config: {
            label: "客户性质",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "0",
            dictCode: "CUST_TYPE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "客户性质必填",
                trigger: "onBlur",
              },
            ],
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx
              if (val === '1') {
                pageContext.pageForm.holder.operator_certificateType = '1'
                pageContext.pageForm.holder.certificateType = '6'
              } else {
                pageContext.pageForm.holder.certificateType = '1'
              }
            }
          },
        },
        {
          prop: "name",
          control: "HInput",
          show: true,
          config: {
            label: "投保人姓名",
            placeholder: "请输入投保人姓名",
            labelWidth: "7em",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "投保人姓名必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateType",
          control: "HSelect",
          show: true,
          config: {
            label: "证件类型",
            placeholder: "请选择证件类型",
            disabled: false,
            val: "1",
            dictCode: "CERTIFICATE_TYPE_PER",
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateNo",
          control: "HInput",
          show: true,
          config: {
            label: "证件号码",
            placeholder: "请输入证件号码",
            disabled: false,
            val: null,
            maxlength: 18,
            toUpperCase: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件号码必填",
                trigger: "onBlur",
              },
              {
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  return validator.certificateNo(pageForm["holder"], val);
                },
                disabled: false,
                message: "证件号码格式输入有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certLongFlag",
          control: "HRadio",
          show: true,
          config: {
            label: "是否长期标识",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            labelWidth: "7em",
            val: "0",
            dictCode: "COMMON_IF",
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              if (val === '1') {
                pageContext.pageForm.holder.certBeginDate = new Date().getTime()
                pageContext.pageForm.holder.certEndDate = new Date("2099-01-01").getTime()
              } else {
                pageContext.pageForm.holder.certEndDate = null
                pageContext.pageForm.holder.certBeginDate = null
              }
            }
          }
        },
        {
          prop: "certBeginDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "证件有效起期",
            placeholder: "请选择证件有效起期",
            labelWidth: "7em",
            disabled: false,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件有效起期必填",
                trigger: "onChange",
                type: "number",
              }
            ],
          },
        },
        {
          prop: "certEndDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "证件有效止期",
            placeholder: "请选择证件有效止期",
            labelWidth: "7em",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件有效止期必填",
                trigger: "onChange",
                type: "number",
              }
            ],
          },
        },
        {
          prop: "gender",
          control: "HRadio",
          show: true,
          config: {
            label: "性别",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "1",
            dictCode: "GENDER",
            rules: [],
          },
        },
        {
          prop: "birthday",
          control: "HDatePicker",
          show: true,
          config: {
            label: "出生日期",
            placeholder: "请选择出生日期",
            disabled: false,
            val: null,
            minDate: dayjs()
              .subtract(100, "year")
              .toDate(),
            maxDate: new Date(),
            rules: [],
          },
        },
        {
          prop: "issuer",
          control: "HInput",
          show: true,
          config: {
            label: "证件签发机关",
            placeholder: "请输入证件签发机关",
            labelWidth: "7em",
            disabled: false,
            val: null,
          },
        },
        {
          control: "HTitle",
          show: true,
          config: {
            text: "",
            style: {
              padding: "5px",
              backgroundColor: "#f7f8fa",
            },
          },
        },
        {
          prop: "mobile",
          control: "HInput",
          show: true,
          config: {
            label: "手机号码",
            placeholder: "请输入手机号码",
            disabled: false,
            type: "number",
            maxlength: 11,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "手机号码必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                message: "手机号格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
        {
          prop: "landlineNumber",
          control: "HInput",
          show: false,
          config: {
            label: "座机",
            placeholder: "请输入座机",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "座机必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^(1[3-9]\d{9})|((0[0-9]{2}[-]*[0-9]{8})|(0[0-9]{3}[-]*[0-9]{7}))$/,
                message: "座机格式有误",
              },
            ],
          },
        },
        {
          prop: "email",
          control: "HInput",
          show: true,
          config: {
            label: "邮箱",
            placeholder: "请输入邮箱",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "邮箱必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "areaCodes",
          control: "HCascader",
          show: true,
          config: {
            label: "所在地区",
            placeholder: "请选择所在地区",
            disabled: false,
            val: null,
            options: areaJson,
            rules: [
              {
                type: "array",
                required: true,
                disabled: false,
                message: "所在地区必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "address",
          control: "HInput",
          show: true,
          config: {
            label: "详细地址",
            placeholder: "请输入详细地址",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "详细地址必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "ethnicGroup",
          control: "HSelect",
          show: true,
          config: {
            label: "民族",
            placeholder: "请选择民族",
            disabled: false,
            val: null,
            dictCode: "ETHNIC_GROUP",
          },
        },
        {
          prop: "homeAddress",
          control: "HInput",
          show: true,
          config: {
            label: "户籍地址",
            placeholder: "请输入户籍地址",
            disabled: false,
            val: null,
            rules: [
                {
                  required: true,
                  disabled: false,
                  message: "户籍地址必填",
                  trigger: "onBlur",
                }
            ]
          },
        },
        {
          prop: "postcode",
          control: "HInput",
          show: true,
          config: {
            label: "邮编",
            placeholder: "请输入邮编",
            type: "number",
            disabled: false,
            val: null,
            maxlength: 6,
            rules: [
              {
                disabled: false,
                message: "邮编格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^\d{6}$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
        {
          prop: "operator",
          control: "HTitle",
          show: false,
          config: {
            text: "联系人信息",
            style: {
              padding: "5px 8px",
              backgroundColor: "#f7f8fa",
              fontSize: "14px",
              color: "#646566",
            },
          },
        },
        {
          prop: "operator_name",
          control: "HInput",
          show: false,
          config: {
            label: "联系人姓名",
            placeholder: "请输入联系人姓名",
            labelWidth: "7em",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "联系人姓名必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "operator_certificateType",
          control: "HSelect",
          show: false,
          config: {
            label: "证件类型",
            placeholder: "请选择证件类型",
            disabled: false,
            val: null,
            dictCode: "AUTO_CERTIFICATE_TYPE_PER",
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "operator_certificateNo",
          control: "HInput",
          show: false,
          config: {
            label: "证件号码",
            placeholder: "请输入证件号码",
            disabled: false,
            val: null,
            maxlength: 18,
            toUpperCase: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件号码必填",
                trigger: "onBlur",
              },
              {
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  return validator.operatorCertificateNo(
                    pageForm["holder"],
                    val
                  );
                },
                disabled: false,
                message: "证件号码格式输入有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "operator_mobile",
          control: "HInput",
          show: false,
          config: {
            label: "手机号码",
            placeholder: "请输入手机号码",
            disabled: false,
            type: "number",
            maxlength: 11,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "手机号码必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                message: "手机号格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
      ],
    };
  },
  insured: (pageCtx, pageForm, pageLayout) => {
    return {
      fmName: "被保人",
      fmCode: "insured",
      show: true,
      formChange: (ctx) => {},
      formItems: [
        {
          prop: "custType",
          control: "HRadio",
          show: true,
          config: {
            label: "客户性质",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "0",
            dictCode: "CUST_TYPE",
            rules: [
              {
                required: true,
                disabled: false,
                message: "客户性质必填",
                trigger: "onBlur",
              },
            ],
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              if (val === '1') {
                pageContext.pageForm.insured.certificateType = '6'
              } else {
                pageContext.pageForm.insured.certificateType = '1'
              }
            }
          },
        },
        {
          prop: "name",
          control: "HInput",
          show: true,
          config: {
            label: "被保人姓名",
            placeholder: "请输入被保人姓名",
            disabled: false,
            maxlength: 20,
            labelWidth: "7em",
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "被保人姓名必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateType",
          control: "HSelect",
          show: true,
          config: {
            label: "证件类型",
            placeholder: "请选择证件类型",
            disabled: false,
            val: "1",
            dictCode: "CERTIFICATE_TYPE_PER",
            rules: [
              {
                disabled: false,
                required: true,
                message: "证件类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certificateNo",
          control: "HInput",
          show: true,
          config: {
            label: "证件号码",
            placeholder: "请输入证件号码",
            disabled: false,
            val: null,
            maxlength: 18,
            toUpperCase: true,
            rules: [
              {
                disabled: false,
                required: true,
                message: "证件号码必填",
                trigger: "onBlur",
              },
              {
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  return validator.certificateNo(pageForm["insured"], val);
                },
                disabled: false,
                message: "证件号码格式输入有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "certLongFlag",
          control: "HRadio",
          show: true,
          config: {
            label: "是否长期标识",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            labelWidth: "7em",
            val: "0",
            dictCode: "COMMON_IF",
            changeEvent: (ctx) => {
              const { pageContext, val } = ctx;
              if (val === '1') {
                pageContext.pageForm.insured.certBeginDate = new Date().getTime()
                pageContext.pageForm.insured.certEndDate = new Date("2099-01-01").getTime()
              } else {
                pageContext.pageForm.insured.certEndDate = null
                pageContext.pageForm.insured.certBeginDate = null
              }
            }
          }
        },
        {
          prop: "certBeginDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "证件有效起期",
            placeholder: "请选择证件有效起期",
            labelWidth: "7em",
            disabled: false,
            val: null,
            maxDate: new Date(),
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件有效起期必填",
                trigger: "onChange",
                type: "number",
              }
            ],
          },
        },
        {
          prop: "certEndDate",
          control: "HDatePicker",
          show: true,
          config: {
            label: "证件有效止期",
            placeholder: "请选择证件有效止期",
            labelWidth: "7em",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件有效止期必填",
                trigger: "onChange",
                type: "number",
              }
            ],
          },
        },
        {
          prop: "gender",
          control: "HRadio",
          show: true,
          config: {
            label: "性别",
            disabled: false,
            checkedColor: "#57ccc2",
            iconSize: "16px",
            val: "1",
            dictCode: "GENDER",
            rules: [],
          },
        },
        {
          prop: "birthday",
          control: "HDatePicker",
          show: true,
          config: {
            label: "出生日期",
            placeholder: "请选择出生日期",
            disabled: false,
            val: null,
            minDate: dayjs()
              .subtract(100, "year")
              .toDate(),
            maxDate: new Date(),
            rules: [],
          },
        },
        {
          prop: "issuer",
          control: "HInput",
          show: true,
          config: {
            label: "证件签发机关",
            placeholder: "请输入证件签发机关",
            labelWidth: "7em",
            disabled: false,
            val: null,
          },
        },
        {
          control: "HTitle",
          show: true,
          config: {
            text: "",
            style: {
              padding: "5px",
              backgroundColor: "#f7f8fa",
            },
          },
        },
        {
          prop: "mobile",
          control: "HInput",
          show: true,
          config: {
            label: "手机号码",
            placeholder: "请输入手机号码",
            disabled: false,
            type: "number",
            maxlength: 11,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "手机号码必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                message: "手机号格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
        {
          prop: "landlineNumber",
          control: "HInput",
          show: false,
          config: {
            label: "座机",
            placeholder: "请输入座机",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "座机必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                pattern: /^(1[3-9]\d{9})|((0[0-9]{2}[-]*[0-9]{8})|(0[0-9]{3}[-]*[0-9]{7}))$/,
                message: "座机格式有误",
              },
            ],
          },
        },
        {
          prop: "email",
          control: "HInput",
          show: true,
          config: {
            label: "邮箱",
            placeholder: "请输入邮箱",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "邮箱必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "areaCodes",
          control: "HCascader",
          show: true,
          config: {
            label: "所在地区",
            placeholder: "请选择所在地区",
            disabled: false,
            val: null,
            options: areaJson,
            rules: [
              {
                type: "array",
                required: true,
                disabled: false,
                message: "所在地区必填",
                trigger: "onChange",
              },
            ],
          },
        },
        {
          prop: "address",
          control: "HInput",
          show: true,
          config: {
            label: "详细地址",
            placeholder: "请输入详细地址",
            disabled: false,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "详细地址必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "ethnicGroup",
          control: "HSelect",
          show: true,
          config: {
            label: "民族",
            placeholder: "请选择民族",
            disabled: false,
            val: null,
            dictCode: "ETHNIC_GROUP",
          },
        },
        {
          prop: "homeAddress",
          control: "HInput",
          show: true,
          config: {
            label: "户籍地址",
            placeholder: "请输入户籍地址",
            disabled: false,
            val: null,
            rules: [
                {
                  required: true,
                  disabled: false,
                  message: "户籍地址必填",
                  trigger: "onBlur",
                }
            ]
          },
        },
        {
          prop: "postcode",
          control: "HInput",
          show: true,
          config: {
            label: "邮编",
            placeholder: "请输入邮编",
            type: "number",
            disabled: false,
            val: null,
            maxlength: 6,
            rules: [
              {
                disabled: false,
                message: "邮编格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^\d{6}$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
        {
          prop: "operator",
          control: "HTitle",
          show: false,
          config: {
            text: "联系人信息",
            style: {
              padding: "5px 8px",
              backgroundColor: "#f7f8fa",
              fontSize: "14px",
              color: "#646566",
            },
          },
        },
        {
          prop: "operator_name",
          control: "HInput",
          show: false,
          config: {
            label: "联系人姓名",
            placeholder: "请输入联系人姓名",
            labelWidth: "7em",
            disabled: false,
            maxlength: 20,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "联系人姓名必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "operator_certificateType",
          control: "HSelect",
          show: false,
          config: {
            label: "证件类型",
            placeholder: "请选择证件类型",
            disabled: false,
            val: null,
            dictCode: "AUTO_CERTIFICATE_TYPE_PER",
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件类型必填",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "operator_certificateNo",
          control: "HInput",
          show: false,
          config: {
            label: "证件号码",
            placeholder: "请输入证件号码",
            disabled: false,
            val: null,
            maxlength: 18,
            toUpperCase: true,
            rules: [
              {
                required: true,
                disabled: false,
                message: "证件号码必填",
                trigger: "onBlur",
              },
              {
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  return validator.operatorCertificateNo(
                    pageForm["insured"],
                    val
                  );
                },
                disabled: false,
                message: "证件号码格式输入有误",
                trigger: "onBlur",
              },
            ],
          },
        },
        {
          prop: "operator_mobile",
          control: "HInput",
          show: false,
          config: {
            label: "手机号码",
            placeholder: "请输入手机号码",
            disabled: false,
            type: "number",
            maxlength: 11,
            val: null,
            rules: [
              {
                required: true,
                disabled: false,
                message: "手机号码必填",
                trigger: "onBlur",
              },
              {
                disabled: false,
                message: "手机号格式有误",
                trigger: "onBlur",
                validator: (rule, value, callback) => {
                  const val = callback ? value : rule;
                  const pattern = /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/;
                  return validator.pattern(null, val, pattern);
                },
              },
            ],
          },
        },
      ],
    };
  },
};
export default {
  scriptConfig: {
    adapterForm: (data) => {
      const { pageCtx, ctx } = data;
      const cloneform = cloneDeep(pageCtx.form);
      return cloneform;
    },
    submitBefore: async (data) => {
      return true;
    },
    submitAfter: (data) => {
      const { pageCtx, ctx, res } = data;
    },
    initAfter: async (data) => {
      const { pageCtx, res } = data;
    },
    initDetailAfter: (data) => {
      const { pageCtx, ctx, res } = data;
    },
  },
  addConfig: (pageCtx, pageForm, pageLayout) => {
    return {
      vehicle: config.vehicle(pageCtx, pageForm, pageLayout),
      owner: config.owner(pageCtx, pageForm, pageLayout),
      holder: config.holder(pageCtx, pageForm, pageLayout),
      insured: config.insured(pageCtx, pageForm, pageLayout),
    };
  },
  editConfig: (pageCtx, pageForm, pageLayout) => {
    return {
      vehicle: config.vehicle(pageCtx, pageForm, pageLayout),
      owner: config.owner(pageCtx, pageForm, pageLayout),
      holder: config.holder(pageCtx, pageForm, pageLayout),
      insured: config.insured(pageCtx, pageForm, pageLayout),
    };
  },
  viewConfig: (pageCtx, pageForm, pageLayout) => {
    return {
      vehicle: config.vehicle(pageCtx, pageForm, pageLayout),
      owner: config.owner(pageCtx, pageForm, pageLayout),
      holder: config.holder(pageCtx, pageForm, pageLayout),
      insured: config.insured(pageCtx, pageForm, pageLayout),
    };
  },
};
