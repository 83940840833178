import { keys } from "lodash";
import common from "@/utils/common";
import dayjs from "dayjs";
function offRequired(config) {
  if (!config.rules) {
    return false;
  }
  config.rules.forEach((item) => {
    if (item.required) {
      item.disabled = true;
    }
  });
}
function onRequired(config) {
  if (!config.rules) {
    return false;
  }
  config.rules.forEach((item) => {
    if (item.required) {
      item.disabled = false;
    }
  });
}
function offDisabled(config) {
  config.disabled = false;
}
function onDisabled(config) {
  config.disabled = true;
}
function offShow(item) {
  item.show = false;
}
function onShow(item) {
  item.show = true;
}
function setDictCode(config, dictCode) {
  config.dictCode = dictCode; //证件类型
}
function onDisabledForm(item, exclude, callBack) {
  keys(item).forEach((key) => {
    if (item[key] && item[key].config) {
      if (exclude && exclude.indexOf(key) > -1) {
        return false;
      }
      item[key].config.disabled = true;
    }
  });
}
function offDisabledForm(item, exclude, callBack) {
  keys(item).forEach((key) => {
    if (item[key] && item[key].config) {
      if (exclude && exclude.indexOf(key) > -1) {
        return false;
      }
      item[key].config.disabled = false;
    }
  });
}
function execCertificateNo(val, form) {
  const certificateType = form && form.certificateType;
  if (!(val && certificateType === "1")) {
    return false;
  }
  if (
    !/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(
      val
    )
  ) {
    return false;
  }
  const birthDay = dayjs(common.getBirthDay(val)).valueOf();
  const gender = common.getGender(val);
  form.birthday = birthDay;
  form.gender = gender;
}
function company(obj) {
  obj.certificateType.config.dictCode = "AUTO_CERTIFICATE_TYPE_PUB"; //证件类型
  this.onShow(obj.landlineNumber); //座机展示
  this.offRequired(obj.mobile.config); //手机非必填
  //不展示“性别、出生日期、民族、家庭地址”
  this.offShow(obj.gender);
  this.offShow(obj.birthday);
  this.offShow(obj.ethnicGroup);
  this.onRequired(obj.homeAddress.config)
  obj.homeAddress.config.label = '注册地址'
  obj.homeAddress.config.placeholder = '请输入注册地址'
}
function person(obj) {
  obj.certificateType.config.dictCode = "AUTO_CERTIFICATE_TYPE_PER";
  this.offShow(obj.landlineNumber); //座机不展示
  this.onRequired(obj.mobile.config); //手机必填
  //展示“性别、出生日期、民族、家庭地址”
  this.onShow(obj.gender);
  this.onShow(obj.birthday);
  this.onShow(obj.ethnicGroup);
  this.onShow(obj.homeAddress);
  this.offRequired(obj.homeAddress.config)
  obj.homeAddress.config.label = '户籍地址'
  obj.homeAddress.config.placeholder = '请输入户籍地址'
}
export default {
  offRequired: offRequired,
  onRequired: onRequired,
  offDisabled: offDisabled,
  onDisabled: onDisabled,
  setDictCode: setDictCode,
  onDisabledForm: onDisabledForm,
  offDisabledForm: offDisabledForm,
  offShow: offShow,
  onShow: onShow,
  execCertificateNo: execCertificateNo,
  company: company,
  person: person,
};
