var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t((_vm.fmCode + "Header"),null,{"form":_vm.form,"formContext":_vm.formContext}),_c('van-form',_vm._b({ref:"Form",attrs:{"model":_vm.form}},'van-form',Object.assign({}, _vm.$attrs, _vm.comProps),false),[_vm._t((_vm.fmCode + "FormPrepend"),null,{"form":_vm.form,"formContext":_vm.formContext}),_vm._l((_vm.formItems()),function(formItem){return [_vm._t((_vm.fmCode + "_" + (formItem.prop)),function(){return [_c('HYRender',{key:formItem.prop,attrs:{"code":formItem.prop,"component-ref":formItem.prop + 'Ref',"control":formItem.control,"config":formItem.config,"form":_vm.form,"on":{
              input: function (val) {
                _vm.form[formItem.prop] = val
              },
              change: function (val) {
                formItem.changeEvent && formItem.changeEvent(val, _vm.formItems())
              }
            }}})]},{"form":_vm.form,"config":formItem.config})]}),_vm._t((_vm.fmCode + "formAppend"),null,{"form":_vm.form,"formContext":_vm.formContext})],2),_vm._t((_vm.fmCode + "Footer"),null,{"form":_vm.form,"formContext":_vm.formContext})],2)}
var staticRenderFns = []

export { render, staticRenderFns }