<template>
  <div>
    <slot :name="`${fmCode}Header`" :form="form" :formContext="formContext"/>
    <van-form
      ref="Form"
      v-bind="{ ...$attrs, ...comProps }"
      :model="form"
    >
      <slot :name="`${fmCode}FormPrepend`" :form="form" :formContext="formContext"/>
      <template v-for="(formItem) in formItems()">
         <slot :name="`${fmCode}_${formItem.prop}`" :form="form" :config="formItem.config">
            <HYRender
              :key="formItem.prop"
              :code="formItem.prop"
              :component-ref="formItem.prop + 'Ref'"
              :control="formItem.control"
              :config="formItem.config"
              :form="form"
              :on="{
                input: (val) => {
                  form[formItem.prop] = val
                },
                change: (val) => {
                  formItem.changeEvent && formItem.changeEvent(val, formItems())
                }
              }"
            />
         </slot>
      </template>
      <slot :name="`${fmCode}formAppend`" :form="form" :formContext="formContext"/>
    </van-form>
    <slot :name="`${fmCode}Footer`" :form="form" :formContext="formContext"/>
  </div>
</template>
<script>
import Vue from 'vue'
import HYRender from '@/components/Render'
const context = require.context('@/components/form-items/', false, /.vue$/)
context.keys().forEach(key => {
  const component = context(key).default
  Vue.component(component.name, component)
})
export default {
  name: 'HYForm',
  inheritAttrs: false,
  components: { HYRender },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        }
      }
    }
  },
  props: {
    fmCode:{
      type: String,
      default: null
    },
    form: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'config': {
      handler (newVal, oldVal) {
        this.init()
      }
    },
    form:{
      handler (newVal, oldVal) {
        this.formItems().map(formItem => {
          if(formItem.config.val === newVal[formItem.prop]){
            return true
          }
          formItem.config.val=newVal[formItem.prop]
        })
      },
      deep:true
    }
  },
  data () {
    return {
      formContext:this
    }
  },
  computed: {
    comProps () {
      return Object.assign({
        inline: false,
        inputAlign:'right',
        errorMessageAlign:'right',
        showError: false,
        labelWidth: '60px',
        scrollToError:true,
        show: true,
        disabled: false,
        size: 'mini',
        label: '',
        formItems: []
      }, this.config)
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 初始化 model
      this.initForm()
    },
    initForm () {
      this.formItems().map(formItem => {
        if(!formItem.prop){
          return false
        }
        if(this.form[formItem.prop]){
          formItem.config.val = this.form[formItem.prop]
          return false
        }
        this.$set(this.form, formItem.prop, formItem.config.val || null)
      })
    },
    formItems () {
      return this.config.formItems.filter(item => item.show)
    },
    clearValidate () {
      this.$nextTick(() => {
        this.$refs['Form'].clearValidate()
      })
    },
    validateForm () {
      return new Promise((resolve) => {
        this.$refs['Form'].validate().then(()=>{
          resolve(true)
        }).catch(()=>{
          resolve(false)
        })
      })
    }
  }
}
</script>
<style lang="scss">
</style>
